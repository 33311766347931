import {
  createChatCompletion,
  createThread,
  deleteThread,
  listMessages,
  submitToolOutputs,
} from "./cloudFunctions"
import {
  getModel,
  getAIUsageParam,
  addMessages,
  convertToMessages,
  runAssistant,
  retrieveRunForStatus,
  getViewSummary,
  FUNCTION_GET_QUESTIONS_COMMON,
  FUNCTION_GET_MODEL_QUESTIONS,
  FUNCTION_GET_EXTENDED_QUESTIONS,
  MAX_MODEL_QUESTIONS,
  MAX_OPEN_QUESTIONS,
  GPT_4o_MINI_LATEST,
  getObjectiveAndContextMessages,
  GPT_o1_MINI_LATEST,
} from "./chatGenerationServices"
import * as palette from "../../components/symbols/palette"
import { v4 as uuidv4 } from "uuid"

// Use GPT threads to create the next best views
const generateQuestions = async ({
  assistants,
  viewSet,
  views,
  generateQuestionFiles,
  setMessage,
  setPercentComplete,
  roles,
}) => {
  const assistant = assistants.find((a) => a.name === "AIM Goal Seeker")

  const aimGoalSeekerAssistantId = assistant.id

  console.log('%cRetrieved assistant "AIM Goal Seeker"', "color:lightgreen", {
    assistant,
  })

  const suggestViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "suggest_views"
  )

  const getModelQuestionsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_questions"
  )

  const getExtendedQuestionsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_questions_plain"
  )

  console.log("%cfunctions", "color:lightgreen", {
    suggestViewsFunction,
    getModelQuestionsFunction,
    getExtendedQuestionsFunction,
  })

  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_GET_QUESTIONS_COMMON,
  })

  const gptModelGetModelQuestions = getModel({
    roles: roles,
    funcName: FUNCTION_GET_MODEL_QUESTIONS,
  })

  const gptModelGetExtendedQuestions = getModel({
    roles: roles,
    funcName: FUNCTION_GET_EXTENDED_QUESTIONS,
  })

  console.log("%cusing model", "color:orange", { model: roles })

  // number of questions of each type for paid account
  const DEFAULT_NUM_QUESTIONS = 25

  const maxModelQuestions =
    getAIUsageParam({ roles, paramName: MAX_MODEL_QUESTIONS }) ||
    DEFAULT_NUM_QUESTIONS
  const maxOpenQuestions =
    getAIUsageParam({ roles, paramName: MAX_OPEN_QUESTIONS }) ||
    DEFAULT_NUM_QUESTIONS

  // element questions are those that have a layer attribute, vs open questions which aren't tied to a specific layer
  const pinnedElementQuestions =
    viewSet.view_questions?.filter((q) => q.layer && q.pinned) || []
  const elementQuestionsToCreate =
    maxModelQuestions - pinnedElementQuestions.length

  // Questions that do not have a 'layer' attribute, and have no 'pinned' attribute, or 'pinned' is false
  const pinnedOpenQuestions =
    viewSet.view_questions?.filter((q) => q.layer === undefined && q.pinned) ||
    []

  const openQuestionsToCreate = maxOpenQuestions - pinnedOpenQuestions.length

  console.log("%cquestions to create", "color:lightGreen", {
    elementQuestionsToCreate,
    openQuestionsToCreate,
    pinnedOpenQuestions,
    questions: viewSet.view_questions,
  })

  const vectorStoreIds = assistant.tool_resources.file_search.vector_store_ids

  //console.log("vector store ids", vectorStoreIds)

  //console.log(`%cUnderstand context`, "color:pink", viewSet.overview)

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id

  const initialMessages = getObjectiveAndContextMessages({ viewSet })

  setMessage("Load context...(step 1 of 4)")
  setPercentComplete(15)

  await addMessages({ threadId, content: convertToMessages(initialMessages) })

  const inputContextRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "input existing context",
    expectedStatus: "completed",
    modelName: GPT_4o_MINI_LATEST,
  })

  console.log("%cinput context run result", "color:orange", {
    inputContextRunResult,
  })

  const viewContext = viewSet.view_context

  const step2Messages = [
    "Given the context and objective, what important questions might you want to answer about this topic? List all of the questions that you think are important to know about this topic, and considering the context. Make sure you have questions that cover all of the ArchiMate element layers, i.e. a good broad selection of questions. We will later use these questions to create views that will help answer them.",
    `Create at ${elementQuestionsToCreate} questions and make sure that questions are relevant, and not just filler questions, and that they are unique.`,
    "Make sure that the questions cover all of the aspects in the researched context.",
    "Do not just create a question per ArchiMate element -- think of the best questions to ask and then map them to the ArchiMate elements.",
    "It is typically important to at least have a few Strategy layer questions, covering Capabilities required, strategic courses of action, and Resources required to achieve the objective.",
  ]

  if (elementQuestionsToCreate > 0) {
    step2Messages.push(
      `There are also some existing questions. Do not create a questions already in this list:`,
      ...pinnedElementQuestions.map((q, index) => `- ${q.question})`)
    )
  }

  await addMessages({ threadId, content: convertToMessages(step2Messages) })

  if (generateQuestionFiles.length > 0) {
    await addMessages({
      threadId,
      content: [
        {
          type: "text",
          text: `Read the file(s) provided and make sure that the questions you create take these files into account as you create questions targeted around the provided objective.`,
        },
      ],
      attachments: generateQuestionFiles.map((file) => ({
        file_id: file.id,
        tools: [{ type: "file_search" }],
      })),
    })
  }

  setMessage("Creating questions... (step 2 of 4)")
  setPercentComplete(30)

  const step2RunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "get model-based questions",
    expectedStatus: "requires_action",
    functionToUse: getModelQuestionsFunction,
    modelName: gptModelGetModelQuestions,
    tools: [{ type: "file_search" }],
  })

  console.log("step 2 run result", { step2RunResult })

  if (step2RunResult.success === false) {
    console.error(
      "Error in step 2 run result",
      step2RunResult.result.data.error
    )
    return
  }

  let layerBasedQuestions
  if (
    step2RunResult.result.data.response?.required_action.submit_tool_outputs
  ) {
    const rawJson =
      step2RunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("%cattempting to parse JSON", "color:pink", { rawJson })

    let questionsJson
    try {
      questionsJson = JSON.parse(rawJson)
    } catch (e) {
      console.error("Error parsing JSON", e)
      return
    }

    layerBasedQuestions = questionsJson.questions.map((q) => ({
      ...q,
      question_id: `question-${uuidv4()}`,
      // Use the layer of the first element in element_types, since this seems more accurate than the layer element returned by the model
      layer:
        q.element_types.length > 0
          ? palette.getElementType(q.element_types[0])?.layer.name
          : q.layer,
    }))

    console.log("%clayer based questions json", "color:yellow", {
      layerBasedQuestions,
    })

    const runId = step2RunResult.result.data.response.id
    const toolCallId =
      step2RunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].id

    const submitToolOutputsResult = await submitToolOutputs({
      threadId,
      runId,
      toolCallId,
      toolOutput: JSON.stringify(layerBasedQuestions),
    })

    console.log("%csubmit tool outputs result: get_questions", "color:pink", {
      submitToolOutputsResult,
    })

    const submittedToolOutputRunResult = await retrieveRunForStatus({
      threadId: threadId,
      runId: runId,
      expectedStatus: "completed",
    })

    console.log("submitted tool output run result", {
      submittedToolOutputRunResult,
    })
  }

  setMessage("Analysing existing views... (step 3 of 4)")
  setPercentComplete(60)

  if (views.length > 0) {
    const viewSummary = views.map((v) => getViewSummary(v))
    const viewsFormatted = viewSummary.map(
      (v, index) => `${index + 1}. Name: ${v.name}, Element types: (${v.types})`
    )
    const step3aMessages = [
      `This is the existing set of views that exist, including their title and the prompts that were used to create them: ${viewsFormatted}`,
      `You do not need to respond to this. I am providing this background information for the next step.`,
    ]

    await addMessages({ threadId, content: convertToMessages(step3aMessages) })

    console.log("%canalyse existing views", "color:orange", { views })

    const step3aRunResult = await runAssistant({
      threadId,
      assistantId: aimGoalSeekerAssistantId,
      usage: "input existing questions",
      additionalInstructions:
        "This is just further background information you will need for the next step.",
      gptModel: GPT_4o_MINI_LATEST,
    })
    console.log("step 3a run", { step3aRunResult })

    if (step3aRunResult.success === false) {
      console.error(
        "Error in step 3a run result",
        step3aRunResult.result.data.error
      )
      return
    }
  } else {
    console.log("No existing views")
  }

  const additionalQuestionsPrepMessages = [
    `You have now created ${elementQuestionsToCreate} model-based questions. These questions are more detailed and specific to the ArchiMate elements. Now we will create another set of questions that are more open-ended and higher level, and not covered by the initial set of questions.`,
    `The initial set of questions are as follow:`,
    ...layerBasedQuestions.map((q, index) => `- ${q.question})`),
    `On top of these questions, and without duplicating what has already been asked, what are the most useful and important questions that you would need to answer to achieve the objective?`,
    `Just provide the questions without any introductory text.`,
  ]

  setMessage("Adding 2nd layer of questions... (step 4 of 4)")
  setPercentComplete(70 )

  const questionPrepResult = await createChatCompletion({
    messages: additionalQuestionsPrepMessages.map((m) => ({
      role: "user",
      content: m,
    })),
    model: GPT_o1_MINI_LATEST,
  })

  console.log("%celement prep result", "color:orange", {
    questionPrepResult,
  })

  const questionPrepText =
    questionPrepResult?.data.response.choices[0].message.content

  console.log("%cqueston prep text", "color:orange", {
    questionPrepText,
  })

  const additionalQuestionMessages = [
    `Now generate ${
      maxModelQuestions - pinnedOpenQuestions.length
    } additional questions. Think about how to achieve the objective and what questions you would need to answer to achieve that. These questions should be more real-world based and at a higher level of abstraction than model-based questions.`,
  ]

  if (openQuestionsToCreate > 0) {
    const allExistingQuestions = layerBasedQuestions.concat(pinnedOpenQuestions)

    console.log("all existing questions", allExistingQuestions)

    additionalQuestionMessages.push(
      `There are also some existing open questions. You MUST NOT create any duplicate questions -- either with the exact same question text, or which is very close in intent to any existing question. Do not create a question if it is in this list:`,
      ...allExistingQuestions.map((q, index) => `- ${q.question})`)
    )
  }

  additionalQuestionMessages.push(
    `You can use these questions as a guide: ${questionPrepText}`
  )

  await addMessages({
    threadId,
    content: convertToMessages(additionalQuestionMessages),
  })

  const additionalQuestionsRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "get extended questions",
    expectedStatus: "requires_action",
    functionToUse: getExtendedQuestionsFunction,
    modelName: gptModelGetExtendedQuestions,
    tools: [{ type: "file_search" }],
  })

  console.log("additional questions run result", {
    additionalQuestionsRunResult,
  })

  if (additionalQuestionsRunResult.success === false) {
    console.error(
      "Error in additional questions run result",
      additionalQuestionsRunResult.result.data.error
    )
    return
  }

  let additionalQuestions

  if (
    additionalQuestionsRunResult.result.data.response?.required_action
      .submit_tool_outputs
  ) {
    const rawJson =
      additionalQuestionsRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].function.arguments

    console.log("%cattempting to parse JSON", "color:pink", { rawJson })

    let questionsJson
    try {
      questionsJson = JSON.parse(rawJson)
    } catch (e) {
      console.error("Error parsing JSON", e)
      return
    }

    additionalQuestions = questionsJson.questions.map((q) => ({
      ...q,
      question_id: `question-${uuidv4()}`,
    }))
    console.log("%cadditional questions", "color:yellow", {
      additionalQuestions,
    })

    const runId = additionalQuestionsRunResult.result.data.response.id
    const toolCallId =
      additionalQuestionsRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].id

    const submitToolOutputsResult = await submitToolOutputs({
      threadId,
      runId,
      toolCallId,
      toolOutput: JSON.stringify(additionalQuestions),
    })

    console.log(
      "%csubmit tool outputs result: get_questions_plain",
      "color:pink",
      { submitToolOutputsResult }
    )

    const submittedToolOutputRunResult = await retrieveRunForStatus({
      threadId: threadId,
      runId: runId,
      expectedStatus: "completed",
    })

    console.log("submitted tool output run result", {
      submittedToolOutputRunResult,
    })

    layerBasedQuestions = layerBasedQuestions.concat(additionalQuestions)
  }

  const step3bMessages = [
    `Select the next 3 most appropriate questions to be answered from the list that you generated, and create views for each of those questions.`,
    `Rule: You MUST only use valid ArchiMate element types as per the type enum in the suggest_views function.`,
    `Use the 'suggest_views' function to respond with your answer.`,
  ]

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return {
    //suggestedViews: suggestedViewsWithUUIDsAndQuestions,
    suggestedViews: [],
    viewContext: viewContext,
    viewQuestions: layerBasedQuestions,
  }
}

export { generateQuestions }
