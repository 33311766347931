import React from "react"
import FormControl from "@mui/material/FormControl"
import { InputAdornment, TextField, Typography } from "@mui/material"

export default function TextInput(props) {
  const {
    name,
    label,
    value,
    onChange,
    helperText,
    multiline = false,
    disabled = false,
    fullWidth = true,
    icon,
    sx = {},
    ...other
  } = props

  const helperTextId = name + "_helper_text"

  const inputProps = icon
    ? {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        },
      }
    : {}

  const disabledStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)", // Match disabled text color
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.38)", // Match disabled border color
    },
    "& .MuiInputLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)", // Match disabled label color
    },
  }

  return (
    <FormControl fullWidth={fullWidth} style={{ margin: 0, padding: 0 }}>
      <TextField
        label={label}
        variant="outlined"
        size="small"
        name={name}
        sx={{
          ...sx,
          ...(disabled ? disabledStyles : {}),
        }} // Merge styles
        id={name}
        {...inputProps}
        value={value}
        multiline={multiline}
        disabled={disabled}
        onChange={onChange}
        aria-describedby={helperTextId}
        helperText={
          <Typography variant="caption" component={"span"}>
            {helperText}
          </Typography>
        }
        {...other}
      />
    </FormControl>
  )
}
