// src/utils/MarkdownToWordConverter.js

import {
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  LevelFormat,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from "docx"

/**
 * Converts Markdown text to an array of Docx elements (Paragraphs, Tables, etc.)
 *
 * @param {object} params - The parameters for conversion.
 * @param {string} params.markdown - The Markdown content to convert.
 * @param {object} params.options - Required parameters.
 * @param {object} params.options.styles - Styles to apply to the Word document.
 * @param {object} params.options.numbering - Numbering configurations.
 * @returns {Array} - An array of Docx elements.
 * @throws {Error} Throws an error if styles or numbering are not provided.
 */
export function convertMarkdownToDocxElements({ markdown, options }) {
  const { styles, numbering } = options

  // Validate that styles and numbering are provided
  if (!styles || !numbering) {
    throw new Error("Both 'styles' and 'numbering' options must be provided.")
  }

  // Replace '&nbsp;' with a space character
  markdown = markdown.replace(/&nbsp;/g, " ")

  // Replace non-breaking spaces (Unicode \u00A0) with regular spaces
  markdown = markdown.replace(/\u00A0/g, " ")

  const lines = markdown.split("\n")
  const elements = []
  let inCodeBlock = false
  let codeBlockLanguage = ""
  let codeBlockContent = ""
  let inTable = false
  let tableHeaders = []
  let tableAlignments = []
  let tableRows = []

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i]

    // Handle code blocks using regex to account for leading spaces and language specifiers
    const codeBlockDelimiterMatch = line.match(/^\s*```(\w+)?\s*$/)
    if (codeBlockDelimiterMatch) {
      if (inCodeBlock) {
        // End of code block
        if (codeBlockLanguage.toLowerCase() === "json") {
          // Insert JSON code block as raw text to preserve comments
          const jsonParagraph = createRawCodeParagraph(
            codeBlockContent,
            codeBlockLanguage
          )
          elements.push(jsonParagraph)
        } else {
          // For other languages, attempt to parse and format
          const formattedParagraph = formatCodeBlock(
            codeBlockContent,
            codeBlockLanguage
          )
          elements.push(formattedParagraph)
        }

        inCodeBlock = false
        codeBlockLanguage = ""
        codeBlockContent = ""
      } else {
        // Start of code block
        inCodeBlock = true
        codeBlockLanguage = codeBlockDelimiterMatch[1] || ""
        codeBlockContent = ""
      }
      continue
    }

    if (inCodeBlock) {
      codeBlockContent += line + "\n"
      continue
    }

    // Handle horizontal rules
    if (/^---+$/.test(line.trim())) {
      elements.push(
        new Paragraph({
          children: [],
          border: {
            bottom: {
              color: "auto",
              space: 1,
              value: "single",
              size: 6,
            },
          },
        })
      )
      continue
    }

    // Handle table rows
    const tableMatch = line.match(/^\|.*\|$/)
    if (tableMatch) {
      // ... (table handling remains the same)
    } else {
      if (inTable) {
        // ... (table handling remains the same)
      }
    }

    // Handle headings with # syntax
    const headingMatch = line.match(/^(\s*)(#{1,6})\s+(.*)/)
    if (headingMatch) {
      const leadingSpaces = headingMatch[1]
      const level = headingMatch[2].length
      const text = headingMatch[3]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces,
              preserve: true,
            }),
            ...parseInlineStyles(text),
          ],
          heading: getHeadingLevel(level),
        })
      )
      continue
    }

    // Handle entirely bolded lines as normal paragraphs with top and bottom margins
    const boldParagraphMatch = line.match(/^(\s*)(\*\*|__)(.+)\2$/)
    if (boldParagraphMatch) {
      const leadingSpaces = boldParagraphMatch[1]
      const text = boldParagraphMatch[3]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces,
              preserve: true,
            }),
            ...parseInlineStyles(
              boldParagraphMatch[2] + text + boldParagraphMatch[2]
            ),
          ],
          style: "Normal",
          spacing: {
            before: 200,
            after: 200,
          },
        })
      )
      continue
    }

    // Handle unordered lists
    const ulMatch = line.match(/^(\s*)([-*+])\s+(.*)/)
    if (ulMatch) {
      const leadingSpaces = ulMatch[1]
      const listMarker = ulMatch[2]
      const text = ulMatch[3]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces,
              preserve: true,
            }),
            new TextRun({
              text: listMarker + " ",
            }),
            ...parseInlineStyles(text),
          ],
          style: "Normal",
          spacing: {
            before: 200,
            after: 200,
          },
        })
      )
      continue
    }

    // Handle ordered lists
    const olMatch = line.match(/^(\s*)(\d+)\.\s+(.*)/)
    if (olMatch) {
      const leadingSpaces = olMatch[1]
      const number = olMatch[2]
      const text = olMatch[3]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces,
              preserve: true,
            }),
            new TextRun({
              text: number + ". ",
            }),
            ...parseInlineStyles(text),
          ],
          style: "Normal",
          spacing: {
            before: 200,
            after: 200,
          },
        })
      )
      continue
    }

    // Handle blockquotes (simple implementation)
    const blockquoteMatch = line.match(/^(\s*)>\s+(.*)/)
    if (blockquoteMatch) {
      const leadingSpaces = blockquoteMatch[1]
      const text = blockquoteMatch[2]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces + "> ",
              preserve: true,
            }),
            ...parseInlineStyles(text),
          ],
          style: "BlockQuote",
        })
      )
      continue
    }

    // Handle paragraphs (including inline styles)
    if (line.trim() !== "") {
      const leadingSpacesMatch = line.match(/^(\s*)(.*)$/)
      const leadingSpaces = leadingSpacesMatch[1]
      const text = leadingSpacesMatch[2]

      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: leadingSpaces,
              preserve: true,
            }),
            ...parseInlineStyles(text),
          ],
          spacing: {
            before: 200,
            after: 200,
          },
          style: "Normal",
        })
      )
    }
  }

  // After processing all lines, if still inside a table, close it
  if (inTable) {
    // ... (table closing remains the same)
  }

  return elements
}

/**
 * Parses inline Markdown styles within a line of text.
 *
 * @param {string} text - The line of text to parse.
 * @returns {Array} - An array of Docx TextRun objects with applied styles.
 */
function parseInlineStyles(text) {
  const textRuns = []
  let lastIndex = 0

  // Regex to match bold, italic, inline code, and hyperlinks
  const regex =
    /(\*\*|__)(.+?)\1|(\*|_)(.+?)\3|`([^`]+)`|\[([^\]]+)\]\(([^)]+)\)/g
  let match

  while ((match = regex.exec(text)) !== null) {
    const index = match.index

    // Add text before the match
    if (index > lastIndex) {
      const plainText = text.slice(lastIndex, index)
      textRuns.push(
        new TextRun({
          text: plainText,
          preserve: /^\s|\s$/.test(plainText),
        })
      )
    }

    if (match[1] && match[2]) {
      // Bold text
      textRuns.push(
        new TextRun({
          text: match[2],
          bold: true,
        })
      )
    } else if (match[3] && match[4]) {
      // Italic text
      textRuns.push(
        new TextRun({
          text: match[4],
          italics: true,
        })
      )
    } else if (match[5]) {
      // Inline code
      textRuns.push(
        new TextRun({
          text: match[5],
          font: { name: "Courier New" },
          color: "808080",
        })
      )
    } else if (match[6] && match[7]) {
      // Link
      textRuns.push(
        new TextRun({
          text: match[6],
          style: "Hyperlink",
          link: match[7],
        })
      )
    }

    lastIndex = regex.lastIndex
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    const remainingText = text.slice(lastIndex)
    textRuns.push(
      new TextRun({
        text: remainingText,
        preserve: /^\s|\s$/.test(remainingText),
      })
    )
  }

  return textRuns
}

/**
 * Calculates the indentation level based on leading spaces or tabs.
 *
 * @param {string} whitespace - The leading whitespace of a line.
 * @returns {number} - The calculated indentation level.
 */
function getIndentLevel(whitespace) {
  // Replace non-breaking spaces with regular spaces in indentation
  whitespace = whitespace.replace(/\u00A0/g, " ")

  // Count tabs as equivalent to 4 spaces
  const spaceCount = whitespace.replace(/\t/g, "    ").length
  // Assuming 2 spaces per indent level
  return Math.floor(spaceCount / 2)
}

/**
 * Creates a Paragraph with raw code, preserving comments and formatting.
 *
 * @param {string} code - The raw code string.
 * @param {string} language - The programming language of the code.
 * @returns {Paragraph} - The constructed Paragraph element.
 */
function createRawCodeParagraph(code, language) {
  const lines = code.split("\n")
  const children = []

  lines.forEach((line, index) => {
    // Preserve indentation via spaces and use monospaced font
    children.push(
      new TextRun({
        text: line,
        font: { name: "Courier New" },
        preserve: true,
      })
    )
    if (index < lines.length - 1) {
      children.push(new TextRun({ break: 1 }))
    }
  })

  return new Paragraph({
    children: children,
    style: "Code",
  })
}

/**
 * Attempts to format a code block by parsing and re-stringifying (for non-JSON).
 *
 * @param {string} code - The code block content.
 * @param {string} language - The programming language of the code.
 * @returns {Paragraph} - The formatted Paragraph element.
 */
function formatCodeBlock(code, language) {
  // Implement parsing and formatting for other languages if needed
  // For simplicity, we'll treat them as raw code similar to JSON
  return createRawCodeParagraph(code, language)
}

/**
 * Maps heading levels to docx HeadingLevel enums.
 *
 * @param {number} level - The heading level (1-6).
 * @returns {HeadingLevel} - The corresponding docx HeadingLevel.
 */
function getHeadingLevel(level) {
  switch (level) {
    case 1:
      return HeadingLevel.HEADING_1
    case 2:
      return HeadingLevel.HEADING_2
    case 3:
      return HeadingLevel.HEADING_3
    case 4:
      return HeadingLevel.HEADING_4
    case 5:
      return HeadingLevel.HEADING_5
    case 6:
      return HeadingLevel.HEADING_6
    default:
      return HeadingLevel.HEADING_1
  }
}
