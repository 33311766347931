import { el } from "date-fns/locale"
import * as palette from "../../components/symbols/palette"

const createElementExtractionPrompt = ({
  inputText,
  typeDef,
  elementTypePrompt,
  mainSubject,
  numberOfLevels
}) => {
  console.log("inputText", {
    mainSubject,
    inputText,
    typeDef,
    elementTypePrompt,
    numberOfLevels
  })

  const typeLabel = palette.formatLabel(typeDef.name)

  const prompt = `You are an expert in analyzing text to extract this information: ${mainSubject}.

---

**Task**:

- **Input Text**: ${inputText}

- **Element Type to Extract**: ${mainSubject}

---

**Instructions**:

1. **Review the Entire Text**:

   - Thoroughly scan the entire input text, including all sections, bullet points, lists, and nested content.

2. **Selective Extraction**:

   - **Extract Only Matching Content**: Identify content that matches '${mainSubject}'.

   - **Ignore Non-Matching Content**: Do **not** include any content that does is not a '${mainSubject}'.

3. **Hierarchy Construction**:

   - **Build Hierarchy from Extracted Elements Only**:

     - **Number of Levels in Hierarchy**: There are ${numberOfLevels} levels in the hierarchy.

     - **Two-Level Hierarchy**: If the extracted elements have sub-elements or nested details that also match the definitions, organize them into a two-level hierarchy where both parent and child elements have a Name and Description.

     - **Single-Level Hierarchy**: If the extracted elements are standalone, list them in a single-level hierarchy, providing the Name and Description for each element.

4. **Output Format**:

   - **Clarity**: Present **only** the extracted elements clearly and in an organized manner.

   - **No Additional Text**: Do **not** include any introductions, explanations, or conclusions. Only provide the extracted elements.

---

**Important Notes**:

- **Focus Solely on Matching Elements**: Your response should **only** include elements that are '${mainSubject}'. Do **not** process or include any other content from the input text.

- **No Assumptions**: Do not assume any specific content or structure in the input text. Only extract elements that match the definitions.

- **Consistency**: Ensure all extracted elements strictly adhere to the provided definitions and maintain consistency in formatting.

---

**Usage Instructions**:

- **Content Type to Extract**: ${mainSubject}

- **Apply Definitions at Runtime**: Use the element definitions provided to ensure the assistant extracts content that matches the current requirements.

- **No Extra Content**: The assistant should **only** extract and include content that matches the definitions.

`

  return prompt
}

export { createElementExtractionPrompt }
