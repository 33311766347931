import { createChatCompletionWithFunctions } from "./cloudFunctions"
import * as palette from "../../components/symbols/palette"
import { getPromptLayers } from "./chatPromptServices"
import { createChatPromptData } from "./modelEditServices"
import {
  createThread,
  deleteThread,
  createRun,
  retrieveRun,
  createMessages,
} from "./cloudFunctions"
import * as diagramToPromptServices from "./diagramToPromptServices"
import * as Roles from "./roleServices"
import _ from "lodash"
import { getQuestionSubject } from "./createContentServices"

// const GPT_4_LATEST = "gpt-4" // gpt-4-1106-preview" // "gpt-4-0613"
// const GPT_4_TURBO_PREVIEW = "gpt-4-turbo-preview"
// const GPT_4_TURBO = "gpt-4-turbo"
// const GPT_3_5_TURBO = "gpt-3.5-turbo-0125"
const GPT_4o_LATEST = "gpt-4o"
const GPT_4o_MINI_LATEST = "gpt-4o-mini"
const GPT_o1_MINI_LATEST = "o1-mini"
//const GPT_o1_MINI_LATEST = "gpt-4o-mini"

const FUNCTION_RESEARCH_CONTEXT = "research_context"
const FUNCTION_GENERATE_PROMPTS = "generate_prompts"
const FUNCTION_GENERATE_INSIGHTS_AND_ACTIONS = "generate_insights_and_actions"
const FUNCTION_CREATE_TITLE_AND_SUMMARY = "create_title_and_summary"
const FUNCTION_GET_BATCH_DESCRIPTIONS = "get_batch_descriptions"
const FUNCTION_GET_SUGGESTED_TAGS = "get_suggested_tags"
const FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL = "get_auto_count_of_next_level"
const FUNCTION_GET_QUESTIONS_COMMON = "get_questions_common"
const FUNCTION_GET_MODEL_QUESTIONS = "get_model_questions"
const FUNCTION_GET_EXTENDED_QUESTIONS = "get_extended_questions"
const FUNCTION_SORT_QUESTIONS = "sort_questions"
const FUNCTION_SORT_VIEWS = "sort_views"
const FUNCTION_CREATE_TEMPLATE = "create_template"

const IS_AIM_TRIAL = true

// const AVERAGE_CHARS_PER_WORD = 6

const MODEL_USAGE = {
  [FUNCTION_RESEARCH_CONTEXT]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GENERATE_PROMPTS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GENERATE_INSIGHTS_AND_ACTIONS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_CREATE_TITLE_AND_SUMMARY]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GET_BATCH_DESCRIPTIONS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GET_SUGGESTED_TAGS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GET_QUESTIONS_COMMON]: {
    model: GPT_4o_LATEST,
  },
  [FUNCTION_GET_MODEL_QUESTIONS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_GET_EXTENDED_QUESTIONS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_SORT_QUESTIONS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_SORT_VIEWS]: {
    model: GPT_4o_MINI_LATEST,
  },
  [FUNCTION_CREATE_TEMPLATE]: {
    model: GPT_4o_MINI_LATEST,
  },
}

const POLICY_TRIAL = "trial"
const POLICY_PAID = "paid"

const QTY_SELECTION_ANY = "any"
const QTY_SELECTION_UPTO = "upto"
const QTY_SELECTION_FIXED = "fixed"

const MIN_CONTEXT_ITEMS = "min_context_items"
const MAX_CONTEXT_ITEMS = "max_context_items"
const MAX_MODEL_QUESTIONS = "max_model_questions"
const MAX_OPEN_QUESTIONS = "max_open_questions"
const MAX_DESCRIPTION_WORDS = "max_description_words"
const MAX_ELEMENTS = "max_elements"
const MAX_VIEWS = "max_views"
const MAX_ACTIONS = "max_actions"
const MAX_INSIGHTS = "max_insights"
const CAN_USE_O1 = "can_use_o1"

const AI_USAGE_POLICY = {
  [POLICY_PAID]: {
    [MIN_CONTEXT_ITEMS]: 8,
    [MAX_CONTEXT_ITEMS]: undefined,
    max_elements: undefined,
    [MAX_MODEL_QUESTIONS]: undefined,
    [MAX_OPEN_QUESTIONS]: undefined,
    // in words
    [MAX_DESCRIPTION_WORDS]: 50,
    [MAX_ELEMENTS]: 30,
    [MAX_VIEWS]: 30,
    [MAX_INSIGHTS]: undefined,
    [MAX_ACTIONS]: undefined,
    [CAN_USE_O1]: true,
  },
  [POLICY_TRIAL]: {
    [MAX_CONTEXT_ITEMS]: 2,
    max_elements: 5,
    [MAX_MODEL_QUESTIONS]: 3,
    [MAX_OPEN_QUESTIONS]: 3,
    [MAX_DESCRIPTION_WORDS]: 20,
    [MAX_ELEMENTS]: 5,
    [MAX_VIEWS]: 5,
    [MAX_INSIGHTS]: 2,
    [MAX_ACTIONS]: 2,
    [CAN_USE_O1]: false,
  },
}

const getModel = ({ roles, funcName }) => {
  if (!roles) {
    console.error("Roles not provided")
  }

  return MODEL_USAGE[funcName]?.model || GPT_4o_LATEST
}

const getAIUsageParam = ({ roles, paramName }) => {
  const isPaid = roles.includes(Roles.AIM_AI)
  const policy = AI_USAGE_POLICY[isPaid ? POLICY_PAID : POLICY_TRIAL]
  const result = policy[paramName]
  // console.log("%cAI usage policy", "color:yellow", {
  //   policy,
  //   paramName,
  //   result,
  // })
  return result
}

// For creating embeddings
const TEXT_EMBEDDING_ADA_002 = "text-embedding-ada-002"

const getSuggestedViewPrompt = async ({
  overview,
  purpose,
  elementType,
  question,
  parentType,
  roles,
}) => {
  const functions = createGptFunctionForSuggestViewPrompt()
  const functionName = functions[0].name

  // const otherViewsList = views
  //   .map((view) => getViewSummary(view))
  //   .map(
  //     (viewSummary, index) =>
  //       `\n${index + 1}. name: '${viewSummary.name}' - ${
  //         viewSummary.description
  //       }, uses element types: ${viewSummary.types}`
  //   )
  //   .join(", ")

  const message = `** Task **
  - Create a prompt to answer the question: ${question}.

  ** Context **
  - The question has been asked in relation to this overall objective: ${purpose}

  ** Instructions **
  - The prompt should request ${palette.formatLabel(elementType)} elements.

  ** Rules **
  - Rule: Do not include the context or objective information in the prompt.
  - Rule: Do not include example data to consider in the prompt. Do not try to answer the question in the prompt.
  - Rule: Do not include specific sample response values in the prompt, as this can influence prompt results. You can include guidance but just not specific examples.
  - Rule: Do not include instructions to 'Consider....' or 'Think about....' in the prompt if that includes sample data that might appear in the response.
`

  const messages = [{ role: "user", content: message }]

  if ([palette.WORK_PACKAGE, palette.DELIVERABLE].includes(elementType)) {
    messages.push({
      role: "system",
      content: `Do not make reference to any timelines or completion dates in the prompt.`,
    })
  }

  if (parentType) {
    messages.push({
      role: "system",
      content: `When writing the prompt consider that the view will be a child of a '${palette.formatLabel(
        parentType
      )}' element`,
    })
  }

  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_GENERATE_PROMPTS,
  })

  const jsonObj = await callGPT({
    messages,
    functions,
    functionName,
    model: gptModel,
  })

  console.log("get suggested view prompt", { overview, jsonObj })

  return { elementType: elementType, ...jsonObj }
}

const getViewSummary = (view) => ({
  name: view.name,
  description: view.description,
  prompts: view.prompt_history,
  types: Array.from(new Set(view.elements.map((el) => el.type)))
    .map((typeId) =>
      palette.formatLabel(palette.getElementTypeByIndex(typeId).name)
    )
    .join(", "),
})

const getObjectiveAndContextMessages = ({ viewSet }) => {
  const messages = [
    `You are a helpful AI assistant that is an expert about achieving the following objective: ${viewSet.purpose}`,
    `Use the following context I will provide to inform future responses.`,
    viewSet.overview,
  ]

  if (viewSet.view_context?.length > 0) {
    const extendedContext = [
      `The extended context information is as follows and this can help inform your responses:`,
      JSON.stringify(viewSet.view_context, null, 2),
    ]
    messages.push(...extendedContext)
  }

  messages.push(
    `Do not respond to this message. This is just to load the context in which views will be created.`
  )

  return messages
}

const suggestElementType = async ({ scope, overview, purpose, prompt }) => {
  const functions = createGptFunctionForSuggestElementType()
  const functionName = functions[0].name

  const messages = [
    {
      role: "system",
      content: `You are an expert in ${scope}`,
    },
    {
      role: "system",
      content:
        "The user wants you to suggest an ArchiMate element type that they should create a view of",
    },
    {
      role: "system",
      content: `The prompt for which the user wants you to suggest an element type is: '${prompt}'. This is the main input you should consider when suggesting an element type.`,
    },
  ]

  if (purpose) {
    messages.push({
      role: "system",
      content: `As extra information, the user's objective is '${purpose}'`,
    })
  }

  if (overview) {
    messages.push({
      role: "system",
      content: `As extra information, the user's context is '${overview}'`,
    })
  }

  messages.push({
    role: "user",
    content: `Suggest an ArchiMate element type that the user should create a view of to achieve their objective.`,
  })

  const jsonObj = await callGPT({ messages, functions, functionName })

  console.log("suggest element type", { overview, jsonObj })

  return jsonObj
}

const runAssistant = async ({
  threadId,
  modelName,
  assistantId,
  usage,
  expectedStatus = "completed",
  additionalInstructions = undefined,
  // has name, description and parameters as top level properties
  functionToUse = undefined,
  updateStatusMessage,
  tools,
  toolChoice,
  waitMillis = 1500,
}) => {
  console.log(
    `%ccreate run params - %c${usage} - %c${
      functionToUse?.function.name || "No function"
    }`,
    "color:lightgreen",
    "color:pink",
    "color:yellow",
    {
      function: functionToUse,
      model: modelName,
    }
  )

  const runParams = {
    threadId: threadId,
    assistantId: assistantId,
    additionalInstructions: additionalInstructions,
    functionToUse: functionToUse,
  }
  if (toolChoice) {
    runParams.toolChoice = toolChoice
  }
  if (tools) {
    runParams.tools = tools
  }
  if (modelName) {
    runParams.modelName = modelName
  }

  const createRunResult = await createRun(runParams)
  console.log("%ccreate run", "color:pink", { runParams, createRunResult })

  const runId = createRunResult.data?.response?.id

  return await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: expectedStatus,
    updateStatusMessage,
    waitMillis,
  })
}

const retrieveRunForStatus = async ({
  threadId,
  runId,
  expectedStatus,
  waitMillis = 3000,
  updateStatusMessage,
}) => {
  const continueStatus = ["in_progress", "queued"]
  const breakStatus = ["completed", "requires_action"]
  const errorStatus = ["expired", "cancelled", "failed", "cancelling"]

  let success = false

  let runResult = undefined

  try {
    while (true) {
      runResult = await retrieveRun({
        threadId: threadId,
        runId: runId,
      })
      console.log(
        "%cpoll run",
        "color:pink",
        runResult.data?.response?.status,
        {
          runResult,
        }
      )

      if (runResult.data?.error) {
        console.error("Error in run", runResult.data.error)
        return { success: false, result: runResult }
      }

      const status = runResult.data?.response?.status

      if (updateStatusMessage) {
        updateStatusMessage(status)
      }

      //console.log("status", status)
      if (status === expectedStatus) {
        success = true
        break
      } else if (breakStatus.includes(status)) {
        break
      } else if (errorStatus.includes(status)) {
        success = false
        break
      }
      // sleep for 4 sec
      await new Promise((resolve) => setTimeout(resolve, waitMillis))
    }

    return { success: success, result: runResult }
  } catch (error) {
    console.error("Error in retrieveRunForStatus", error)
    return { success: false, result: runResult }
  }
}

const addMessages = async ({
  threadId,
  role = "user",
  content,
  attachments,
}) => {
  console.log("%cadding messages", "color:yellow", {
    content,
    attachments,
  })

  const result = await createMessages({
    threadId,
    role,
    content: content,
    attachments: attachments,
  })

  console.log("%cadded messages", "color:dodgerblue", {
    result,
  })

  const isError = result.find((item) => item.data.error)

  if (isError) {
    console.error("%cError adding messages", "color:red", isError)
  }

  return result
}

const generateInsightsAndActions = async ({
  assistants,
  views,
  scope,
  overview,
  purpose,
  roles,
}) => {
  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_GENERATE_INSIGHTS_AND_ACTIONS,
  })
  // Get details for each current view, incl. diagram elements, title, direct_statement, and prompts used to create the diagram
  const diagramDetails = views.map((view) => {
    const promptData = createChatPromptData({
      currentView: view,
      selectedItemId: undefined,
      hiddenProps: [],
    })

    const result = getPromptLayers(promptData)

    const diagramDescription = diagramToPromptServices.createChatPrompt({
      promptData,
      promptLayers: result,
      includeProperties: true,
      includeDoco: true,
    })

    console.log("%cprompt data for view", "color:lightgreen", {
      view: view.name,
      diagramDescription,
      scope,
      overview,
      purpose,
    })

    return {
      id: view.id,
      name: view.name,
      direct_statement: view.direct_statement,
      prompt_history: view.prompt_history,
      text_response: view.text_response || "",
      element_types: Array.from(
        new Set(
          view.elements.map((el) => palette.getElementTypeByIndex(el.type).name)
        )
      ),
      diagram: diagramDescription,
    }
  })

  const assistant = assistants.find((a) => a.name === "AIM Consultant")

  const aimConsultantAssistantId = assistant.id

  console.log('%cUsing assistant "AIM Consultant" with id', "color:yellow", {
    aimConsultantAssistantId,
  })

  console.log("%cloaded consultant assistant", "color:lightgreen", {
    assistant,
    assistants,
  })

  const createInsightsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "sort_diagrams"
  )

  const maxActions = getAIUsageParam({ roles, paramName: MAX_ACTIONS })
  const maxInsights = getAIUsageParam({ roles, paramName: MAX_INSIGHTS })

  if (maxActions) {
    const actionRef =
      createInsightsFunction.function.parameters.properties.diagrams.items
        .properties.actions
    actionRef.minItems = maxActions
    actionRef.maxItems = maxActions
  }

  if (maxInsights) {
    const insightsRef =
      createInsightsFunction.function.parameters.properties.diagrams.items
        .properties.insights
    insightsRef.minItems = maxInsights
    insightsRef.maxItems = maxInsights
  }

  console.log("%cfunction", "color:lightgreen", {
    createInsightsFunction: createInsightsFunction,
  })

  if (!createInsightsFunction) {
    console.error("No 'sort_diagrams' function found")
    return
  }

  const threadResult = await createThread({
    messages: [],
  })

  console.log("created thread with messages", { threadResult })

  const threadId = threadResult.data?.response?.id

  // const diagramMessages = diagramDetails.map((detail) => {
  //   const diagramContent = [
  //     `** Diagram: ${detail.name} [diagram_id: ${detail.id}]`,
  //     `- element_types: ${detail.element_types.join(", ")}`,
  //     `- prompts: ${detail.prompt_history.join(", ")}`,
  //     `- diagram: ${detail.diagram}`,
  //     `- text_response: ${
  //       detail.text_response || "No additional diagram information provided."
  //     }`,
  //   ]

  //   if (detail.direct_statement) {
  //     diagramContent.push(`- direct_statement: ${detail.direct_statement}`)
  //   }

  //   return diagramContent
  // })

  const messageLines = [
    `** Task **
      - Review the diagram and create insights and actions that will help the owner of the objective achieve their objective.
      - The actions provided should be easy to follow and stated in a way that helps take specific and practical steps towards the objective.
      - The insights provided can be drawn from the information provided below, and also supplemented with your own knowledge and experience.

    ** Objective **
      ${purpose}

    ** Inputs **
      - For each diagram I will provide a name, element_types, direct_statement, text_response, and prompts used to create the view.
      - 'element_types' are the ArchiMate element types that are used in the view.
      - 'prompts' are the prompts that were used to generate the view.
      - 'direct_statement' is a crisp summary of the view that clearly states why this part is crucial for achieving the main objective.
      - 'text_response' is further research that you can use in addition to the diagram content and element_types information.
      - 'importance' is a measure of how crucial the view is to achieving the main objective, and also explains why you might fail to achieve the objective if this view is not considered.

    ** Diagram Inputs Information **
      This information is for the diagram: '${
        diagramDetails[0].name
      } [diagram_id: ${diagramDetails[0].id}]'.
      - element types used in view: ${diagramDetails[0].element_types.join(
        ", "
      )} 
      - prompts used to create the view: ${diagramDetails[0].prompt_history.join(
        ", "
      )}
    
    ** Diagram **
     This is the diagram that you need to review and provide insights and actions for:
     `,
    ...diagramDetails[0].diagram,
    `** Diagram Text Response **
      This is extra information that you can use to help you provide insights and actions for the diagram:
      ${diagramDetails[0].text_response}
      `,
  ]

  console.log("%cmessages", "color:pink", { messages: messageLines, views })

  const addMsgResult = await addMessages({
    threadId,
    content: convertToMessages(messageLines),
  })

  console.log("added messages to thread, result", { addMsgResult })

  const createRunResult = await runAssistant({
    threadId,
    assistantId: aimConsultantAssistantId,
    usage: "generate insights and actions",
    expectedStatus: "requires_action",
    functionToUse: createInsightsFunction,
    modelName: gptModel,
  })

  const runId = createRunResult.result.data.response.id

  console.log("%ccreated run", "color:lightgreen", { createRunResult, runId })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("generate insights result", { result })

  let createInsightsActionsResult

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const sortViewsJSON = JSON.parse(rawJson)

    createInsightsActionsResult = sortViewsJSON
  }

  console.log("%cinsights and actions result", "color:yellow", {
    createInsightsActionsResult,
  })

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return createInsightsActionsResult
}

const sortViewsForPresentation = async ({
  assistants,
  views,
  scope,
  overview,
  purpose,
  roles,
}) => {
  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_SORT_VIEWS,
  })

  // Get details for each current view, incl. diagram elements, title, direct_statement, and prompts used to create the diagram
  const diagramDetails = views.map((view) => {
    const promptData = createChatPromptData({
      currentView: view,
      selectedItemId: undefined,
      hiddenProps: [],
    })

    const result = getPromptLayers(promptData)

    const diagramDescription = diagramToPromptServices.createChatPrompt({
      promptData,
      promptLayers: result,
      includeProperties: true,
      includeDoco: true,
    })

    console.log("%cprompt data for view", "color:lightgreen", {
      view: view.name,
      diagramDescription,
    })

    return {
      id: view.id,
      name: view.name,
      direct_statement: view.direct_statement,
      prompt_history: view.prompt_history,
      element_types: Array.from(
        new Set(
          view.elements.map((el) => palette.getElementTypeByIndex(el.type).name)
        )
      ),
      diagram: diagramDescription,
    }
  })

  const assistant = assistants.find((a) => a.name === "AIM Consultant")

  const aimConsultantAssistantId = assistant.id

  console.log('%cUsing assistant "AIM Consultant" with id', "color:yellow", {
    aimConsultantAssistantId,
  })

  console.log("%cloaded consultant assistant", "color:lightgreen", {
    assistant,
    assistants,
  })

  const sortFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "sort"
  )

  console.log("%cfunction", "color:lightgreen", {
    sortFunction,
  })

  if (!sortFunction) {
    console.error("No 'sort_diagrams' function found")
    return
  }

  const threadResult = await createThread({
    messages: [],
  })

  console.log("created thread with messages", { threadResult })

  const threadId = threadResult.data?.response?.id

  const diagramMessages = diagramDetails.map((detail) => {
    const diagramContent = [
      `** Diagram: ${detail.name} [diagram_id: ${detail.id}]`,
      `- element_types: ${detail.element_types.join(", ")}`,
      `- prompts: ${detail.prompt_history.join(", ")}`,
      `- diagram: ${detail.diagram}`,
    ]

    if (detail.direct_statement) {
      diagramContent.push(`- direct_statement: ${detail.direct_statement}`)
    }

    return diagramContent
  })

  const messageLines = [
    `I want you to sort all diagrams that I provide in a logical order that effectively builds toward the overall goal of: ${purpose}`,
    `For each diagram I will provide a name, element_types, direct_statement, and prompts used to create the view`,
    "The 'element_types' are the ArchiMate element types that are used in the view",
    "The 'prompts' are the prompts that were used to generate the view",
    "The 'direct_statement' is a crisp summary of the view that clearly states why this part is crucial for achieving the main objective",
    `The context in which these diagrams are created is: ${scope}. The brief context is: ${overview}`,
    `The diagram already exists and so do not suggest any actions that involve creating new elements or changing the existing elements`,
    `There are ${views.length} diagrams to sort. the text '** Diagram' marks the beginning of a new diagram.`,
    `You MUST sort all ${views.length} provided diagrams:`,
    `You will be presenting to the owner of the objective, and your insights and actions must be helpful to them to achieve their objective.`,
    ...diagramMessages.flat(),
  ]

  console.log("messages", { messageLines, views })

  const addMsgResult = await addMessages({
    threadId,
    content: convertToMessages(messageLines),
  })

  console.log("added messages to thread, result", { addMsgResult })

  const createRunResult = await runAssistant({
    threadId,
    assistantId: aimConsultantAssistantId,
    usage: "sort views for presentation",
    expectedStatus: "requires_action",
    functionToUse: sortFunction,
    modelName: gptModel,
  })

  const runId = createRunResult.result.data.response.id

  console.log("%ccreated run", "color:lightgreen", { createRunResult, runId })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("sort views result", { result })

  let sortViewsResult

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const sortViewsJSON = JSON.parse(rawJson)

    sortViewsResult = sortViewsJSON
  }

  console.log("presentation order", { sortViewsResult })

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return sortViewsResult
}

const createConsultingSummary = async ({
  currentView,
  objective,
  assistants,
  roles,
}) => {
  // const assistants = await listAssistants()
  // console.log("%clist assistants", "color:lightGreen", { assistants })

  const chatPromptData = createChatPromptData({
    currentView: currentView,
    hiddenProps: [],
    includeDoco: true,
  })

  const gptModel = getModel({
    roles,
    funcName: FUNCTION_CREATE_TITLE_AND_SUMMARY,
  })

  console.log("%cchat prompt data", "color:lightgreen", {
    currentView,
    chatPromptData,
  })

  const assistant = assistants.find((a) => a.name === "AIM Consultant")

  const aimConsultantAssistantId = assistant.id

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id

  const msgs = [
    `Imagine you are creating an exec level presentation`,
    `I want you to analyse this view information and provide a suggested slide title, as well as a punchy direct statement of no more than 20 words that clearly states why this part is crucial for achieving the main objective of: ${objective}`,
    `The how_to_read explanation must be no more than 75 words.`,
    `Analyse the following view information.`,
    `--- BEGIN ---`,
  ]

  if (currentView.elements.length > 0) {
    msgs.push(JSON.stringify(chatPromptData))
  }
  if (currentView.text_response !== "") {
    msgs.push(currentView.text_response)
  }

  msgs.push(`--- END ---`)

  console.log("%cmessages", "color:lightgreen", { msgs })

  await addMessages({
    threadId,
    content: convertToMessages(msgs),
    // content: convertToMessages([
    //   `Imagine you are creating an exec level presentation`,
    //   `I want you to analyse this view information:${JSON.stringify(
    //     chatPromptData
    //   )}`,
    //   `The prompts used to create the view are as follows, and should be considered in your analysis: ${currentView.prompt_history.join(
    //     ", "
    //   )}`,
    //   `Provide a suggested slide title, as well as a punchy direct statement of no more than 20 words that clearly states why this part is crucial for achieving the main objective of: ${objective}`,
    //   `The how_to_read explanation must be no more than 75 words.`,
    // ]),
  })

  const createTitleAndSummaryFunction = assistant.tools.find(
    (t) =>
      t.type === "function" && t.function.name === "create_title_and_summary"
  )

  console.log("%cfunction", "color:lightgreen", {
    createTitleAndSummaryFunction,
  })

  if (!createTitleAndSummaryFunction) {
    console.error("No 'create_title_and_summary' function found")
    return
  }

  const createRunResult = await runAssistant({
    threadId,
    assistantId: aimConsultantAssistantId,
    usage: "create consulting summary",
    expectedStatus: "requires_action",
    functionToUse: createTitleAndSummaryFunction,
    modelName: gptModel,
  })

  const runId = createRunResult.result.data.response.id

  console.log("%ccreated run", "color:lightgreen", { createRunResult, runId })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("consulting summary result", { result })

  let consultingSummary

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const consultingSummaryJSON = JSON.parse(rawJson)

    consultingSummary = consultingSummaryJSON
  }

  console.log("consulting summary", { consultingSummary })

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  return consultingSummary
}

const convertToMessages = (strArray) => {
  return strArray.map((str) => ({
    type: "text",
    text: str,
  }))
}

const getSuggestedTags = async ({
  assistants,
  scope,
  overview,
  view,
  purpose,
  roles,
}) => {
  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_GET_SUGGESTED_TAGS,
  })
  const assistant = assistants.find((a) => a.name === "AIM Property Suggester")

  const aimPropertySuggesterAssistantId = assistant.id

  console.log(
    '%cRetrieved assistant "AIM Property Suggester"',
    "color:lightgreen",
    {
      assistant,
    }
  )

  const suggestTagsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_suggested_tags"
  )

  if (!suggestTagsFunction) {
    console.error("No 'get_suggested_tags' function found")
    return
  }

  console.log("%cfunction", "color:lightgreen", {
    suggestTagsFunction,
  })

  const threadResult = await createThread({
    messages: [],
  })

  const promptData = createChatPromptData({
    currentView: view,
    selectedItemId: undefined,
    hiddenProps: [],
  })

  const result = getPromptLayers(promptData)

  const diagramDescription = diagramToPromptServices.createChatPrompt({
    promptData,
    promptLayers: result,
    includeProperties: true,
    includeDoco: true,
  })

  console.log("thread result", { threadResult })

  const threadId = threadResult.data?.response?.id

  const initialMessages = [
    `You are an expert in ${scope}`,
    `I want you to suggest tags that can be used to describe the context of: ${overview}`,
    `The overarching purpose of this context is to support this objective: ${purpose}`,
    `The tags you suggest should be relevant to the context and purpose provided and allow for a more detailed understanding of the diagram.`,
    `The prompt used to generate this diagram are as follows and should help you determine what tags could be most relevant: ${view.prompt_history.join(
      ", "
    )}`,
    `Do not suggest tags that are the same as the diagram elements. The purpose of the tags is that their meaning is orthogonal to the diagram elements, and allows comparing and contrasting of diagram elements. For example, in a capability model it might be useful to add a Criticality tag, or in a Data Objects diagram it might be useful to add a Data Volume tag to understand how much of each data element there could be in an IT platform, and for a Stakeholder view it could be useful to add an Importance tag.`,
    `Different tags will be useful for different types of diagrams, so consider the type of diagram and its element types when suggesting tags.`,
    `The diagram you should examine and suggest tags for is as follows:`,
    `${diagramDescription}`,
  ]

  console.log("%cinitial messages", "color:pink", { initialMessages })

  await addMessages({ threadId, content: convertToMessages(initialMessages) })

  const suggestTagsRunResult = await runAssistant({
    threadId,
    assistantId: aimPropertySuggesterAssistantId,
    usage: "suggest tags",
    expectedStatus: "requires_action",
    functionToUse: suggestTagsFunction,
    modelName: gptModel,
  })

  console.log("%ccontext run result", "color:orange", { suggestTagsRunResult })

  let suggestedTags

  if (
    suggestTagsRunResult.result.data.response?.required_action
      .submit_tool_outputs
  ) {
    const rawJson =
      suggestTagsRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].function.arguments

    console.log("context json", { contextJson: rawJson })

    suggestedTags = JSON.parse(rawJson)
  }

  // Make sure tag names have a space between each capital letter. If they don't, add a space
  const formattedTags = {
    ...suggestedTags.tags,
    tags: suggestedTags.tags.map((tag) => {
      const newTag = {
        ...tag,
        name: tag.name.replace(/([A-Z])/g, " $1").trim(),
      }

      return newTag
    }),
  }

  console.log("formatted tags", { formattedTags })

  return suggestedTags.tags
}

const createSingleSuggestedView = async ({
  assistants,
  overview,
  purpose,
  model = GPT_4o_MINI_LATEST,
  question,
}) => {
  const assistant = assistants.find((a) => a.name === "AIM Goal Seeker")

  const aimGoalSeekerAssistantId = assistant.id

  // console.log('%cRetrieved assistant "AIM Goal Seeker"', "color:lightgreen", {
  //   assistant,
  // })

  const suggestViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "suggest_views"
  )

  const modifiedSuggestViewsFunction = _.cloneDeep(suggestViewsFunction)

  if (question.layer) {
    const elementTypesForLayer = palette
      .getElements()
      .filter((el) => el.layer.name === question.layer)
      .map((el) => el.name)

    console.log("%celement types for layer", "color:lightgreen", {
      layer: question.layer,
      elementTypesForLayer,
    })

    modifiedSuggestViewsFunction.function.parameters.properties.next_best_views.items.properties.element_types.items.properties.type.enum =
      elementTypesForLayer

    console.log("%cmodified function types", "color:lightgreen", {
      suggestViewsFunction,
      modifiedSuggestViewsFunction,
    })
  }

  const threadResult = await createThread({
    messages: [],
  })

  console.log("thread result", { threadResult })
  const threadId = threadResult.data?.response?.id

  const subject = await getQuestionSubject({ question: question.question })

  const initialMessages = [
    `** Context **
    - The user is seeking to achieve this overall objective: ${purpose}.
    - As part of that objective that are seeking to answer this question: ${question.question}.
    - The main subject of the question is: ${subject}.

    ** Task **
    - Suggest a view that will help the user answer the above question, and in particular it is important that you select the best ArchiMate element type and layer for the view.
    - Carefully consider the meaning of each ArchiMate element in the element_types.txt file and suggest_views_guidance.txt when recommending the best layer fit for a question.
    - Only suggest 1 ArchiMate element type that best matches the main subject of the question: ${subject}.`,
  ]

  if (question.element_types) {
    initialMessages.push(
      `The element types that should be considered for this question are: ${question.element_types.join(
        ", "
      )}`
    )
  }

  await addMessages({ threadId, content: convertToMessages(initialMessages) })

  const suggestViewRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "suggest views",
    expectedStatus: "requires_action",
    functionToUse: modifiedSuggestViewsFunction,
    modelName: model,
    tools: [{ type: "file_search" }],
  })

  console.log("%ccontext run result", "color:orange", {
    suggestViewRunResult,
    subject,
    elementTypes: question.element_types,
  })

  let suggestedView

  if (
    suggestViewRunResult.result.data.response?.required_action
      .submit_tool_outputs
  ) {
    const rawJson =
      suggestViewRunResult.result.data.response.required_action
        .submit_tool_outputs.tool_calls[0].function.arguments
    const suggestedViewJSON = JSON.parse(rawJson)
    console.log("context json", { contextJson: suggestedViewJSON })
    suggestedView = suggestedViewJSON.next_best_views[0]
  }
  return suggestedView
}

const isPaid = ({ roles }) => {
  return roles.includes(Roles.AIM_AI)
}

const createViewSetContext = async ({
  assistants,
  viewSet,
  setMessage,
  roles,
  contextInputFiles,
}) => {
  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_RESEARCH_CONTEXT,
  })

  const assistant = assistants.find((a) => a.name === "AIM Goal Seeker")

  const aimGoalSeekerAssistantId = assistant.id

  const getContextFactsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "get_context_facts"
  )

  const initialMessages = [
    `You are a helpful AI assistant that is an expert about: ${viewSet.overview}`,
    `Think about what types of expertise is required to successfully plan and deliver the defined objective, and provide context information relevant to those possibly broad skillsets.`,
    `Provide specific information and facts that will help inform achieving my objective, including any substantial trends, immediate and substantial challenges, or top line imformation information to know about. I do not want you to identify areas that you think are important to know about, but rather provide the information directly.`,
    `Also include some information on the broader context in which my objective is situated.`,
    `If you provide statistics or numbers, please provide the source of the information.`,
  ]

  const maxContextItems = getAIUsageParam({
    roles,
    paramName: MAX_CONTEXT_ITEMS,
  })

  const minContextItems = getAIUsageParam({
    roles,
    paramName: MIN_CONTEXT_ITEMS,
  })

  if (minContextItems) {
    initialMessages.push(
      `Provide at least ${minContextItems} facts to help inform achieving my objective.`
    )
  }

  if (!isPaid({ roles })) {
    initialMessages.push(
      `Limit your response to ${maxContextItems} facts or less.`
    )
  }

  setMessage("Researching context...")

  console.log("%cusing context input files", "color:lightgreen", {
    contextInputFiles,
  })

  const threadResult = await createThread({
    messages: [],
  })

  console.log("thread result", { threadResult, model: gptModel })

  const threadId = threadResult.data?.response?.id

  await addMessages({ threadId, content: convertToMessages(initialMessages) })

  if (contextInputFiles.length > 0) {
    await addMessages({
      threadId,
      content: [
        {
          type: "text",
          text: `Read this file and use whatever information is relevant to help provide context information. Try to provide a citation of the provided file(s) if it makes sense, so that the end user can see the provided file(s) were reviewed.`,
        },
      ],
      attachments: contextInputFiles.map((file) => ({
        file_id: file.id,
        tools: [{ type: "file_search" }],
      })),
    })
  }

  const contextRunResult = await runAssistant({
    threadId,
    assistantId: aimGoalSeekerAssistantId,
    usage: "research view context",
    expectedStatus: "requires_action",
    functionToUse: getContextFactsFunction,
    tools: [],
    modelName: gptModel,
  })

  console.log("%ccontext run result", "color:orange", { contextRunResult })

  let viewContext

  if (
    contextRunResult.result.data.response?.required_action.submit_tool_outputs
  ) {
    const rawJson =
      contextRunResult.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments
    const contextJson = JSON.parse(rawJson)
    console.log("context json", { contextJson })
    viewContext = contextJson.context_items

    console.log("context", { viewContext })
  }

  // Delete thread

  const deleteThreadResult = await deleteThread({ threadId: threadId })
  console.log("delete thread result", { deleteThreadResult })

  return viewContext
}

const checkModellingGoalCompleteness = async ({
  scope,
  overview,
  purpose,
  views,
}) => {
  const functions = createGptFunctionForCheckingGoalCompleteness()

  const functionName = functions[0].name

  const viewSummary = views.map((v) => getViewSummary(v))

  const viewsFormatted = viewSummary.map(
    (v, index) => `${index + 1}. Name: ${v.name}, Types: (${v.types})`
  )

  const messages = [
    {
      role: "system",
      content: `You are an expert in ${scope}.`,
    },
    {
      role: "system",
      content:
        "Your task is to consider the user's 'context', 'objective', and best practice Enterprise Architecture modelling approaches guided by The Open Group Architecture Development Methodology, and elements in the ArchiMate standard, and assess if the user has created all the views that they need to achieve their objective.",
    },
    {
      role: "system",
      content: `Following are the 'context for you making a recommendation, the user's 'objective', a list of the 'existing views' already created, and a list of 'candidate views' that you can select from to make your recommendation`,
    },
    {
      role: "system",
      content: `context: ${overview}`,
    },
    {
      role: "system",
      content: `objective: ${purpose}.`,
    },
    {
      role: "system",
      content: `existing views:\n${viewsFormatted.join("\n")}`,
    },
    {
      role: "user",
      content: `Assess if the user has created all the views that they need to achieve their objective.`,
    },
  ]

  const jsonObj = await callGPT({ messages, functions, functionName })

  console.log("check modelling completeness", { overview, jsonObj })

  return jsonObj
}

const checkIfScopeSpecifiesOverallOutcome = async ({ overview, purpose }) => {
  const functions = createGptFunctionForScopeOutcomeCheck()
  const functionName = functions[0].name

  const messages = [
    {
      role: "user",
      content: `Analyse if an user's objective is defined: '${purpose}', and that it makes sense in the defined context: '${overview}'.`,
    },
    {
      role: "user",
      content: `The objective could be something like, 'develop a strategic roadmap', 'scope out a project', 'develop an IT architecture', or some other goal`,
    },
    {
      role: "user",
      content:
        "In the revised context that you provide, make sure all detail from the original context is retained.",
    },
    {
      role: "user",
      content:
        "Also assess if the context seems appropriate given the objective.",
    },
  ]

  const jsonObj = await callGPT({ messages, functions, functionName })

  console.log("check scope", { overview, jsonObj })

  return jsonObj
}

const callGPT = async ({
  messages,
  functions,
  functionName,
  model = GPT_4o_LATEST,
}) => {
  let result
  try {
    result = await createChatCompletionWithFunctions({
      messages: messages,
      model: model,
      funcs: functions,
      function_call: { name: functionName },
    })
    console.log("result", result)

    // check if this is a deadline-exceeded firebase cloud function error
  } catch (e) {
    if (
      result.data.error &&
      result.data.error.message.includes("deadline-exceeded")
    ) {
      console.log("deadline exceeded")
      return { error: "deadline-exceeded" }
    }
    console.log("error", e)
    return { error: e }
  }
  const response = result.data.response

  if (result.data.error) {
    return { error: result.data.error }
  }

  const jsonStr = response.choices[0].message.function_call.arguments

  try {
    console.log("jsonStr", { jsonStr, response })
    const jsonObj = JSON.parse(jsonStr)
    console.log("jsonObj", jsonObj)

    return jsonObj
  } catch (e) {
    console.log("error parsing json", { jsonStr, e })
    return { error: e }
  }
}

const createBusinessProcess = async ({
  name,
  parentElement,
  handlePasteAdd,
  currentView,
}) => {
  console.log("create business process", { name })

  const functions = createGptFunctionsForBusinessProcess()
  const functionName = functions[0].name

  console.log("created functions", functions)

  const messages = [
    {
      role: "system",
      content: `You are an AI assistant, acting as an expert Enterprise Architect, specializing in the domain of 'Business Processes' and managing the lifecycle of information across said process.`,
    },
    {
      role: "system",
      content: "A business process is a collection of business functions",
    },
    {
      role: "system",
      content: `Each business function has at least 1 business object input and 1 business object output, but could have more of each.`,
    },
    {
      role: "system",
      content: `The way in which business functions are sequenced is entirely dependent on the information inputs required, and so if Business Function B requires, say, 'Invoice' information as an input, and Business Function A outputs 'Invoice' information then Business Function B comes after Business Function A'.`,
    },
    {
      role: "system",
      content: `The business functions are sequenced in a way that ensures that all information inputs are satisfied before a business function is executed.`,
    },
    {
      role: "system",
      content: `The business functions do not necessarily need to be sequentially executed. It could be that Function A enables Function B and C to be completed, and that Function B triggers Function D. It could also be that some function join again, e.g. C and D could join to enable execution of Function E, however some functions branches can just end along the way, i.e. fork but not rejoin.`,
    },
    {
      role: "system",
      content:
        "For each business function also provide up to 6 key requirements. Do NOT include the common non-functional requirements like scalability, security, etc. I only want the relevant functional requirements related specifically to the business function.",
    },
    {
      role: "user",
      content: `List all the business functions in a business process called: '${name}'. For each business function list the business object inputs and outputs.`,
    },
    {
      role: "user",
      content: `Provide a 'name', 'inputs', and 'outputs', and 'requirements' for each business function ONLY, where 'requirements' is an array of objects each with a 'name' and 'description' attribute.`,
    },
  ]

  let result
  try {
    result = await createChatCompletionWithFunctions({
      messages: messages,
      model: GPT_4o_LATEST,
      funcs: functions,
      function_call: { name: functionName },
    })
    console.log("result", result)

    // check if this is a deadline-exceeded firebase cloud function error
  } catch (e) {
    if (
      result.data.error &&
      result.data.error.message.includes("deadline-exceeded")
    ) {
      console.log("deadline exceeded")
      return { error: "deadline-exceeded" }
    }
    console.log("error", e)
    return { error: e }
  }
  const response = result.data.response

  if (result.data.error) {
    return { error: result.data.error }
  }

  const jsonStr = response.choices[0].message.function_call.arguments

  console.log("jsonStr", jsonStr)
  const jsonObj = JSON.parse(jsonStr)["elements"]
  console.log("jsonObj", jsonObj)

  const busObjTypeDef = palette.getElementType(palette.BUSINESS_OBJECT)
  const busFuncTypeType = palette.getElementType(palette.BUSINESS_FUNCTION)
  const requirementsTypeDef = palette.getElementType(palette.REQUIREMENT)

  const elementsToAdd = jsonObj.map((json) => {
    return {
      name: json.name,
      type: busFuncTypeType.index,
      children: [
        ...json.inputs.map((input) => ({
          name: input,
          type: busObjTypeDef.index,
        })),
        ...json.outputs.map((output) => ({
          name: output,
          type: busObjTypeDef.index,
        })),
        ...json.requirements.map((req) => ({
          name: req.name,
          description: req.description,
          type: requirementsTypeDef.index,
        })),
      ],
    }
  })

  console.log("elementsToAdd", elementsToAdd)

  handlePasteAdd({
    elementDataToAdd: elementsToAdd,
    parent: parentElement,
    viewElements: currentView.elements,
  })
}

const getChatCompletionResult = async ({
  messages,
  model = GPT_4o_LATEST,
  functions,
  function_call,
}) => {
  let result
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("%cget completion", "color:pink", {
        messages,
        model,
        functions,
        function_call,
      })
    }
    result = await createChatCompletionWithFunctions({
      messages: messages,
      model,
      funcs: functions,
      function_call,
    })
    console.log("result", result)
  } catch (e) {
    console.log("error", e)
    return { error: e }
  }
  //const response = result.data.response

  if (result.data.error) {
    return { error: result.data.error }
  } else {
    const funcCallResultStr =
      result.data.response.choices[0].message.function_call.arguments
    return { jsonStr: funcCallResultStr }
  }
}

/**
 * Generate properties and tag against elements. Prop has name and description
 *
 * @param {*} elements | The elements to add properties to
 * @param {*} prop | The property to add
 */
const createElementProperties = async ({
  elements,
  elementProps,
  elementType,
  override,
  gptModel = GPT_4o_LATEST,
}) => {
  console.log("create element properties", {
    elements,
    elementProps,
    elementType,
    override,
  })

  const typeDef = palette.getElementTypeByIndex(elementType)
  const prop = elementProps[0]

  const elementsToUse = override
    ? elements
    : elements.filter(
        (element) =>
          element.props.find(
            (p) => p.name.toLowerCase() === prop.name.toLowerCase()
          ) === undefined
      )

  console.log("elementsToUse", { elementsToUse, override })

  const messages = [
    {
      role: "user",
      content: `I want you to generate a series of '${prop.name}' values for some elements, which is defined as '${prop.description}'.}`,
    },
    {
      role: "user",
      content: `Provide a single '${
        prop.name
      }' value for every one of these elements: ${elementsToUse
        .map((el) => `'${el.name}'`)
        .join(", ")}.`,
    },
    {
      role: "user",
      content: `The elements you are providing a value for are of type ${typeDef.name}`,
    },
    {
      role: "user",
      content:
        //"You must give your response as a JSON array, where each array element has a 'name' attribute, which is the input element name, and a 'values' attribute which is 0 or more values for the property.",
        "Your response MUST be a JSON array. Each element in the array MUST only have the following attributes:",
    },
    {
      role: "system",
      content: `You must provide a '${prop.name}' value for all elements, and the reason why you selected that value.`,
    },
  ]

  const functions = [
    {
      name: "get_properties",
      description: "Get descriptions for elements",
      parameters: {
        type: "object",
        properties: {
          descriptions: {
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  description:
                    "The name of the element to generate the description for",
                  enum: elementsToUse.map((el) => el.name),
                },
                value: {
                  type: "string",
                  description: `The value for the '${prop.name}' property`,
                },
                reason: {
                  type: "string",
                  description: `The reason explaining why you selected the value for the property`,
                },
              },
              required: ["name", "value", "reason"],
            },
          },
        },
        required: ["properties"],
      },
    },
  ]

  if (process.env.NODE_ENV === "development") {
    console.log("generating property", { messages, functions })
  }

  try {
    const result = await createChatCompletionWithFunctions({
      messages: messages,
      model: gptModel,
      funcs: functions,
      function_call: { name: "get_properties" },
    })
    console.log("%cgenerated properties", "color:lightgreen", result)

    const response = result.data.response

    if (result.data.error) {
      return { error: result.data.error }
    }

    const funcCallStr = response.choices[0].message.function_call.arguments

    console.log("funcCallStr", funcCallStr)

    const json = JSON.parse(funcCallStr)

    console.log("json", json)

    return json["descriptions"]
  } catch (err) {
    console.log("Error getting element properties", { err })
    throw err
  }
}

const createGptFunctionForSuggestViewPrompt = () => {
  const functionName = "suggest_view_prompt"

  const parameters = {
    type: "object",
    properties: {
      prompt: {
        type: "string",
        description: `The prompt to achieve the user's objective in their defined context. Write this so that it can be pasted into ChatGPT. Do not reference other views in this prompt. Do not include sample response values in this prompt.`,
      },
      // referenced_views: {
      //   type: "array",
      //   description: `The other existing view names that would be a useful input when creating this view, if any.`,
      //   items: {
      //     properties: {
      //       name: {
      //         type: "string",
      //         description: `The name of the other view to use as context when creating this view`,
      //       },
      //     },
      //     required: ["name"],
      //   },
      // },
    },
    //required: ["prompt", "referenced_views"],
    required: ["prompt"],
  }

  const functions = [
    {
      name: functionName,
      description: "Suggest a prompt for the user to use",
      parameters: parameters,
    },
  ]

  return functions
}

const createGptFunctionForCheckingGoalCompleteness = () => {
  const functionName = "check_goal_completeness"

  const parameters = {
    type: "object",
    properties: {
      completion_percentage: {
        type: "integer",
        description: `A number between 1 and 100 which indicates how well the current views support the user's objective.`,
      },
      completion_recommendation: {
        type: "string",
        description: `A summary which lists the remaining questions that could be asked that would help support reaching the objective. You should include explaining what the views should focus on and the rationale for this in terms of how it supports achieving the objective. Put each suggested view on a new line.`,
      },
      completion_progress: {
        type: "string",
        description: `A summary which provide a summarised list explaining how well the views support the user's objective. Provide a fairly detailed analysis of how the existing views support the objective and how the views can be referenced by someone to provide a specific recommendation or summary, and what that summary might be. If any views are incomplete, explain why they are incomplete and how to complete them. You MUST check that any references to ArchiMate elements are valid ArchiMate elements, and not made up.`,
      },
    },
    required: [
      "completion_percentage",
      "completion_recommendation",
      "completion_progress",
    ],
  }

  const functions = [
    {
      name: functionName,
      description: "Check if goal is completed",
      parameters: parameters,
    },
  ]

  return functions
}

const createGptFunctionForSuggestElementType = () => {
  const functionName = "suggest_element_type"

  const parameters = {
    type: "object",
    properties: {
      type: {
        type: "string",
        description: `The suggested ArchiMate element type to use.`,
        enum: palette.getElementTypeNames(),
      },
      reason: {
        type: "string",
        description: `The reason why this element type is useful for this specific view.`,
      },
    },
    required: ["type", "reason"],
  }

  const functions = [
    {
      name: functionName,
      description: "Suggest an element type",
      parameters: parameters,
    },
  ]

  return functions
}

const createGptFunctionForScopeOutcomeCheck = () => {
  const functionName = "check_scope_outcome"

  const parameters = {
    type: "object",
    properties: {
      defines_purpose: {
        type: "boolean",
        description: `Indicates if a objective has been sufficiently specified, i.e. what is the end goal and results that the user is trying to achieve.`,
      },
      context_matches_purpose: {
        type: "boolean",
        description: `Indicates if the context seems appropriate given the objective`,
      },
      context_purpose_feedback: {
        type: "string",
        description: `The feedback on the context in relation to the objective`,
      },
    },
    required: [
      "defines_purpose",
      "context_matches_purpose",
      "context_purpose_feedback",
    ],
  }

  const functions = [
    {
      name: functionName,
      description: "Check if scope outcome is satisfied",
      parameters: parameters,
    },
  ]

  return functions
}

const createGptFunctionsForBusinessProcess = () => {
  const functionName = "create_business_process"

  const parameters = {
    type: "object",
    properties: {
      elements: {
        type: "array",
        items: {
          properties: {
            name: {
              type: "string",
              description: `The name of the Business Function`,
            },
            inputs: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                    description: `The name of the Business Object Input`,
                  },
                },
                required: ["name"],
              },
            },
            outputs: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                    description: `The name of the Business Object Output`,
                  },
                },
              },
            },
            requirements: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                    description: `The name of the Business Function Requirement`,
                  },
                  description: {
                    type: "string",
                    description: `The description of the Business Function Requirement`,
                  },
                },
              },
            },
          },
          required: ["name"],
        },
      },
    },
    required: ["elements"],
  }

  const functions = [
    {
      name: functionName,
      description: "Create Business Proxess",
      parameters: parameters,
    },
  ]

  return functions
}

const getJSONObj = (str) => {
  const pattern = /\{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/

  // Find the JSON object within the text.
  const match = str.match(pattern)

  if (match) {
    // Extract the JSON object as a string.
    const jsonString = match[0]

    // Parse the JSON object.
    const jsonObject = JSON.parse(jsonString)

    //console.log("JSON object:", jsonObject)
    return jsonObject
  } else {
    console.log("No JSON object found in the text.")
    return null
  }
}

function removeTrailingCommas(jsonString) {
  const regex = /,\s*([}\]])/g
  const result = jsonString.replace(regex, "$1")
  return result
}

const getJSONArray = (str) => {
  const jsonArrayRegex = /\[(?:[^\[\]]|\[[^\[\]]*\])*\]/g
  const jsonArrayMatches = str.match(jsonArrayRegex)

  if (jsonArrayMatches && jsonArrayMatches.length > 0) {
    const jsonStr = removeTrailingCommas(jsonArrayMatches[0])
    const result = JSON.parse(jsonStr)
    //console.log("found JSON data", result)
    return result
  } else {
    //console.log("No JSON array found.")
    return []
  }
}

const getLines = (content) => {
  const jsonArrayRegex = /\[(?:[^\[\]]|\[[^\[\]]*\])*\]/g
  const jsonArrayMatches = content.match(jsonArrayRegex)

  if (jsonArrayMatches && jsonArrayMatches.length > 0) {
    try {
      const jsonStr = removeTrailingCommas(jsonArrayMatches[0])
      const result = [{ type: "json", content: JSON.parse(jsonStr) }]
      //console.log("found JSON data", result)
      return result
    } catch (e) {
      console.log("Error parsing JSON", { e, jsonArrayMatches })
      return []
    }
  } else {
    console.log("No JSON array found.")
    return []
  }
}

export {
  getChatCompletionResult,
  getLines,
  getJSONObj,
  createViewSetContext,
  checkIfScopeSpecifiesOverallOutcome,
  checkModellingGoalCompleteness,
  createConsultingSummary,
  getSuggestedViewPrompt,
  createBusinessProcess,
  generateInsightsAndActions,
  createSingleSuggestedView,
  suggestElementType,
  runAssistant,
  sortViewsForPresentation,
  getObjectiveAndContextMessages,
  getViewSummary,
  getSuggestedTags,
  createElementProperties,
  addMessages,
  convertToMessages,
  retrieveRunForStatus,
  GPT_4o_LATEST,
  GPT_4o_MINI_LATEST,
  GPT_o1_MINI_LATEST,
  TEXT_EMBEDDING_ADA_002,
  MODEL_USAGE,
  AI_USAGE_POLICY,
  MAX_DESCRIPTION_WORDS,
  MAX_ELEMENTS,
  MAX_OPEN_QUESTIONS,
  MAX_MODEL_QUESTIONS,
  CAN_USE_O1,
  MAX_VIEWS,
  FUNCTION_RESEARCH_CONTEXT,
  FUNCTION_GENERATE_PROMPTS,
  FUNCTION_GENERATE_INSIGHTS_AND_ACTIONS,
  FUNCTION_CREATE_TITLE_AND_SUMMARY,
  FUNCTION_GET_BATCH_DESCRIPTIONS,
  FUNCTION_GET_SUGGESTED_TAGS,
  FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL,
  FUNCTION_CREATE_TEMPLATE,
  FUNCTION_GET_QUESTIONS_COMMON,
  FUNCTION_GET_MODEL_QUESTIONS,
  FUNCTION_GET_EXTENDED_QUESTIONS,
  FUNCTION_SORT_QUESTIONS,
  IS_AIM_TRIAL,
  QTY_SELECTION_ANY,
  QTY_SELECTION_UPTO,
  QTY_SELECTION_FIXED,
  getAIUsageParam,
  getModel,
}
