import { Autocomplete, TextField } from "@mui/material"
import { useEffect, useState } from "react"

const ModelEditViewSetFiles = ({
  label = "Files",
  viewSet,
  viewSetFiles,
  setViewSetFiles,
}) => {
  const [viewSetFileOptions, setViewSetFileOptions] = useState([])

  useEffect(() => {
    if (viewSet) {
      const viewSetFiles = viewSet.docs || []

      console.log("viewSetFiles", viewSetFiles)

      const options = viewSetFiles.map((file) => {
        return { id: file.file_id, label: file.name }
      })

      setViewSetFileOptions(options)
    }
  }, [viewSet])

  return (
    <Autocomplete
      multiple
      id="view_set_files"
      options={viewSetFileOptions || []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoComplete
      autoHighlight
      autoCorrect="on"
      size="small"
      value={viewSetFiles}
      onChange={(e, newValue) => {
        setViewSetFiles(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          sx={{ maxWidth: "500px" }}
        />
      )}
    />
  )
}

export default ModelEditViewSetFiles
