import {
  createChatCompletionWithFunctions,
  createThread,
  deleteThread,
  retrieveThread,
  listVectorStoreFiles,
  deleteOpenAIVectorStore,
  listMessages,
  retrieveFile,
  createChatCompletion,
  listRunSteps,
} from "./cloudFunctions"
import {
  getModel,
  FUNCTION_GET_BATCH_DESCRIPTIONS,
  GPT_4o_LATEST,
  GPT_4o_MINI_LATEST,
  runAssistant,
  addMessages,
  GPT_o1_MINI_LATEST,
  QTY_SELECTION_ANY,
  QTY_SELECTION_UPTO,
  QTY_SELECTION_FIXED,
} from "./chatGenerationServices"
import {
  createChatPrompt,
  createPromptDataFromModelCache,
} from "./chatPromptServices"
import { createChatPromptData } from "./modelEditServices"
import * as palette from "../../components/symbols/palette"
import { addIds, getRootElements } from "./modelEditServices"
import { createModelCacheKey, searchModelCache } from "./modelServices"
import db from "../../Firestore"
import { AIM_AI } from "./roleServices"
import { createElementExtractionPrompt } from "./createElementExtractionPrompt"

const AVERAGE_CHARS_PER_WORD = 6

const createContent = async ({
  accountId,
  modelCache,
  scope,
  overview,
  viewSet,
  views,
  // Optional parent element under which content is generated
  currentElement,
  currentView,
  maxElementId,
  levelSpecs,
  elementDefinitions,
  handlePasteAdd,
  // This is the text response that accompanies the diagram
  setTextResponse,
  setWaitingElementIds,
  stopRequested,
  setGeneratingContentMessage,
  roles,
  vectorStores,
  // Files selected to be used in this prompt
  viewSetFiles,
  assistants,
  // GPT model passed in as per selection from user from UI
  gptModel,
  // true => normal ArchiMate element based response, false => plain text response not tied to ArchiMate elements
  useElements,
  // true => elements should be driven off the text response, false => elements should be created independently of the text response
  alignElements,
}) => {
  console.log("%cGenerating content", "color:lightgreen", {
    levelSpecs,
    currentElement,
    currentView,
    maxElementId,
    gptModel,
  })

  const root = []

  let currentMaxId = maxElementId

  let prevResult

  let pastedElements

  let theView = { ...currentView }

  // console.log("%ccreate content =>", "color:lightgreen", {
  //     ids: currentView.elements.map((el) => el.id).join(", "),
  //     currentMaxId: `${currentMaxId}`,
  // })

  console.log("%clevelSpecs", "color:lightgreen", { levelSpecs })

  for (const [index, levelSpec] of levelSpecs.entries()) {
    //console.log("is stop requested", { stopRequested })
    if (stopRequested.value === true) {
      console.log("stop requested")
      return { error: "stop requested" }
    }

    const { viewPrompts, missingViews } = await getPromptsForReferencedViews({
      accountId,
      views,
      modelCache,
      prompt: levelSpec.info,
    })

    const loadedViewRefs = await loadDesignViewRefs({
      viewRefs: missingViews,
      accountId,
    })

    const designViewPrompts = loadedViewRefs.map((v) => ({
      src: v.src,
      prompt: createViewPrompt({
        view: v.view,
      }),
    }))

    const allViewPrompts = [...viewPrompts, ...designViewPrompts]

    if (index === 0) {
      setGeneratingContentMessage(
        `Generate level ${index + 1} of ${levelSpecs.length}`
      )

      if (currentElement) {
        setWaitingElementIds((curr) => [...curr, currentElement.id])
      }

      console.log("%clevelSpec", "color:pink", { levelSpec })

      const nextLevel = await createElementLevel({
        topLevel: true,
        referencedViewPrompts: allViewPrompts,
        scope,
        overview,
        // 'item' may or may not be set
        parentElement: currentElement,
        currentView: theView,
        //levelSpec: levelSpecWithChildrenAttr,
        levelSpec: levelSpec,
        elementDefinitions,
        handlePasteAdd,
        setWaitingElementIds,
        root,
        viewSet,
        model: gptModel,
        vectorStores,
        viewSetFiles,
        assistants,
        roles,
        setTextResponse,
        useElements,
        alignElements,
      })

      if (nextLevel.error) {
        return nextLevel
      }

      theView = { ...theView, elements: nextLevel.pastedElements }

      if (nextLevel.text_response) {
        theView.text_response = nextLevel.text_response
      }

      //console.log("%cGenerated next level", "color:yellow", nextLevel)

      prevResult = nextLevel

      if (currentElement) {
        setWaitingElementIds((curr) =>
          curr.filter((id) => id !== currentElement.id)
        )
      }

      currentMaxId = addIds(nextLevel.json, currentMaxId)

      pastedElements = handlePasteAdd({
        elementDataToAdd: nextLevel.json,
        parent: currentElement,
        viewElements: theView.elements,
        textResponse: nextLevel.text_response,
      })

      // console.log("%cAdded elements", "color:lightgreen", {
      //     updatedElements: pastedElements,
      //     added: nextLevel.json,
      //     ids: pastedElements.map((element) => element.id).join(", "),
      // })
    } else {
      const leafNodes = getLeafNodes(prevResult.json)

      // Create element level for all leaf nodes
      const nextLevels = []

      // Take a deep copy of root so when we call getAllSiblings it isn't affected by the loop below updating the 'children' attribute of elements

      //const rootDeepCopy = JSON.parse(JSON.stringify(root))

      setGeneratingContentMessage(
        `Generate level ${index + 1} of ${levelSpecs.length}`
      )

      for (const [index, leafNode] of leafNodes.entries()) {
        setWaitingElementIds((curr) => [...curr, leafNode.id])

        const { viewPrompts, missingViews } =
          await getPromptsForReferencedViews({
            accountId,
            views,
            modelCache,
            prompt: levelSpec.info,
          })

        const loadedViewRefs = await loadDesignViewRefs({
          viewRefs: missingViews,
          accountId,
        })

        const designViewPrompts = loadedViewRefs.map((v) => ({
          src: v.src,
          prompt: createViewPrompt({
            view: v.view,
          }),
        }))

        const allViewPrompts = [...viewPrompts, ...designViewPrompts]

        const nextLevel = await createElementLevel({
          topLevel: false,
          referencedViewPrompts: allViewPrompts,
          scope,
          overview,
          // Item for which breakdown is being created
          parentElement: leafNode,
          levelSpec,
          elementDefinitions,
          currentView: theView,
          handlePasteAdd,
          setWaitingElementIds,
          context: leafNode,
          root,
          viewSet,
          model: gptModel,
          // for the 2nd level and beyond, we don't allow use of vector stores for now
          vectorStores: [],
          viewSetFiles: [],
          assistants,
          roles,
          useElements,
          alignElements,
        })

        if (nextLevel.error) {
          console.log("error occurred", nextLevel.error)
          return nextLevel
        }

        prevResult = nextLevel

        theView = { ...theView, elements: nextLevel.pastedElements }

        setWaitingElementIds((curr) => curr.filter((id) => id !== leafNode.id))

        leafNode.children = nextLevel.json

        //console.log("%cadded leaf node children", "color:yellow", { leafNode, root })

        nextLevels.push(nextLevel.json)
        currentMaxId = addIds(nextLevels, currentMaxId)
        pastedElements = handlePasteAdd({
          elementDataToAdd: nextLevel.json,
          parent: currentElement,
          viewElements: theView.elements,
        })
      }
    }
  }

  if (stopRequested.value === true) {
    console.log("stop requested")
    return
  }

  return pastedElements
}

/**
 *
 * @param {*} qty = number of elements per level
 */
const createElementLevel = async ({
  topLevel,
  referencedViewPrompts,
  scope,
  overview,
  parentElement,
  levelSpec,
  elementDefinitions,
  handlePasteAdd,
  currentView,
  setWaitingElementIds,
  // callback to check if stop was requested
  hasStopBeenRequested,
  context,
  viewSet,
  //Default model, but usually overridden with GPT-4
  model = GPT_4o_LATEST,
  vectorStores,
  viewSetFiles,
  assistants,
  roles,
  setTextResponse,
  // true => normal ArchiMate element based response, false => plain text response not tied to ArchiMate elements
  useElements,
  alignElements,
}) => {
  if (hasStopBeenRequested) {
    console.log("stop requested")
    return { error: "stop requested" }
  }

  const viewCreatorAssistant = assistants.find(
    (assistant) => assistant.name === "AIM View Creator"
  )

  let elementTypePrompt
  const typeDef = palette.getElementTypeByIndex(levelSpec.type)

  if (useElements) {
    // Use user-defined element type prompts if one exists, otherwise use the default element type prompt
    const elementTypeDefinition = elementDefinitions.find(
      (ed) => ed.type === typeDef.name
    )
    elementTypePrompt = elementTypeDefinition
      ? elementTypeDefinition.prompt
      : typeDef.label
  }

  const allVectorStoreIds = [
    // don't need to add assistant vector store ids again to thread
    // both the assistant and thread vector stores get searched
    //...assistantVectorStoreIds,
    ...vectorStores.map((vs) => vs.vs_id),
  ]

  const threadParams = { messages: [] }
  if (allVectorStoreIds.length > 0) {
    threadParams.toolResources = {
      file_search: { vector_store_ids: allVectorStoreIds },
    }
  }

  console.log("%ccreating thread", "color:lightgreen", { threadParams })

  const threadResult = await createThread(threadParams)
  console.log("%cthread result", "color:orange", { threadResult })
  const threadId = threadResult.data?.response?.id

  let elementPrepResult

  let prepText2

  if (topLevel) {
    if (viewSetFiles.length > 0) {
      // If input files are provided we need to use the thread to read the files, since chatcompletion doesn't support file reading
      prepText2 = await getLevelPrepTextWithThread({
        elementTypePrompt,
        levelSpec,
        purpose: viewSet.purpose,
        roles,
        topLevel,
        typeDef,
        threadId,
        inputFiles: viewSetFiles,
        viewCreatorAssistant,
        useElements,
      })
    } else {
      prepText2 = await getLevelPrepTextWithChatCompletion({
        elementTypePrompt,
        levelSpec,
        purpose: viewSet.purpose,
        roles,
        topLevel,
        typeDef,
      })
    }

    // Display this as the text part of the view for the user to read while we move on to create the model elements for this view.
    setTextResponse(prepText2)

    // if (useElements) {
    //   // we only need to post-process the initial text if we are creating ArchiMate elements, vs OpenAI plain text
    //   elementPrepResult = await postProcessElementPrepText({
    //     prepText: prepText2,
    //     levelSpec,
    //     elementTypePrompt,
    //     typeDef,
    //   })
    // }
  }

  if (!useElements) {
    // Clean up thread and return empty result

    if (threadId) {
      const deleteThreadResult = await deleteThread({ threadId: threadId })
      console.log("delete thread result", { deleteThreadResult })
    }

    // We've already done setTextResponse, so for plain text response, we don't need to return anything else.
    return {
      json: [],
      pastedElements: [],
      text_response: "",
    }
  }

  // We only get past this point if we are generating ArchiMate elements, vs plain text response

  if (levelSpec.qty_selection === QTY_SELECTION_UPTO) {
    const autoCountResult = await getAutoCountOfNextLevel({
      referencedViewPrompts,
      viewSet,
      levelSpec,
      parentElement,
      gptModel: model,
    })
    if (autoCountResult.error) {
      return { error: autoCountResult.error }
    }
    levelSpec.qty_to_use = Math.min(levelSpec.qty, autoCountResult.count)
  } else {
    levelSpec.qty_to_use = levelSpec.qty
  }

  const baseMessages = []

  if (parentElement) {
    // Find all siblings to parentElement in currentView

    const parentOfSelected = currentView.elements.find((el) =>
      el.children.find((c) => c.id === parentElement.id)
    )
    //console.log("%cparentOfSelected", "color:lightgreen", { parentOfSelected })
    if (parentOfSelected) {
      const siblingIds = parentOfSelected.children
        .map((c) => c.id)
        .filter((id) => id !== parentElement.id)
      const siblings = currentView.elements.filter((el) =>
        siblingIds.includes(el.id)
      )
      //console.log("%csiblings", "color:lightgreen", { siblings })
      baseMessages.push({
        role: "system",
        content: getAvoidSiblingOverlapPrompt({ parentElement, siblings }),
      })
      baseMessages.push({
        role: "system",
        content: `The parent element is '${parentElement.name}', and so do not include that in the child elements you provide.`,
      })
    } else {
      const siblings = getRootElements({
        elements: currentView.elements,
      }).filter((item) => item.id !== parentElement.id)
      console.log("%csiblings", "color:lightgreen", {
        currentView,
        parentElement,
        siblings,
      })
      baseMessages.push({
        role: "user",
        content: getAvoidSiblingOverlapPrompt({ parentElement, siblings }),
      })
      // We are NOT going to add all top level elements in as sibling information at this stage.
    }
  }

  if (levelSpec.info !== "") {
    console.log("%clevelSpec.info", "color:lightgreen", {
      prompt: levelSpec.info,
      viewSetFiles,
    })
  }

  // const getMainSubjectResult = await createChatCompletion({
  //   messages: [
  //     {
  //       role: "user",
  //       content: `From the following question, identify the main items or things that are being requested or inquired about.
  //         Provide only the plural noun or noun phrase representing these items, using the exact words from the question.
  //         Include necessary qualifiers or context to precisely identify the items, but exclude any action verbs or additional explanations.
  //         For example, if the question is "What strategies can we implement to improve customer satisfaction?", the response should be "Customer satisfaction improvement strategies".
  //         Do not overly include qualifiers, use only the most directly related qualifier to the items.`,
  //     },
  //     {
  //       role: "user",
  //       content: `${currentView.question || levelSpec.info}`,
  //     },
  //   ],
  //   model: GPT_o1_MINI_LATEST,
  // })

  // const mainSubject =
  //   getMainSubjectResult.data.response.choices[0].message.content

  // console.log("%cgetMainSubjectResult", "color:lightgreen", {
  //   getMainSubjectResult,
  //   mainSubject,
  // })

  const mainSubject = await getQuestionSubject({
    question: currentView.question || levelSpec.info,
  })

  const getMainSubjectElements = await createChatCompletion({
    messages: [
      {
        role: "user",
        content: `Extract all the ${mainSubject} elements from the INPUT TEXT.`,
      },
      {
        role: "user",
        content: `Provide only the extracted ${mainSubject} elements as your response, strictly without any additional text.`,
      },
      {
        role: "user",
        content: "Provide the response as a multilevel numbered list",
      },
      { role: "user", content: `--- INPUT TEXT ---\n${prepText2}\n--- END` },
    ],
    model: GPT_o1_MINI_LATEST,
  })

  const mainSubjectElementsText =
    getMainSubjectElements.data.response.choices[0].message.content

  console.log("%cgetMainSubjectElements", "color:lightgreen", {
    getMainSubjectElements,
    mainSubjectElementsText,
  })

  const mainSubjectLevels = await createChatCompletion({
    messages: [
      {
        role: "user",
        content: `Identify the number of indentation levels of ${mainSubject} elements in the INPUT TEXT, and only answer with a single integer number. Ignore any introductory text when counting the levels.`,
      },
      {
        role: "user",
        content: `--- INPUT TEXT ---\n${mainSubjectElementsText}\n--- END`,
      },
    ],
    model: GPT_o1_MINI_LATEST,
  })

  const mainSubjectLevelsText =
    mainSubjectLevels.data.response.choices[0].message.content

  console.log("%cmainSubjectLevels", "color:lightgreen", {
    mainSubjectLevels,
    mainSubjectLevelsText,
  })

  if (topLevel && alignElements) {
    baseMessages.push({
      role: "user",
      content: createElementExtractionPrompt({
        inputText: mainSubjectElementsText,
        typeDef,
        elementTypePrompt,
        mainSubject,
        numberOfLevels: mainSubjectLevelsText,
      }),
    })
  }

  // if (topLevel) {
  //   baseMessages.push({
  //     role: "user",
  //     content: `This is some important input content between BEGIN and END below that you should extract from to create your response. Do not include any introductory or conclusion text.`,
  //   })
  //   baseMessages.push({
  //     role: "user",
  //     //content: `BEGIN ---\n${elementPrepResult}\n--- END`,
  //     content: `BEGIN ---\n${prepText2}\n--- END`,
  //   })
  //   //if (levelSpec.levels === 1) {
  //   baseMessages.push({
  //     role: "user",
  //     content: `Analyise the input content to locate all ${palette.formatLabel(
  //       typeDef.name
  //     )} content. It may not be neatly organised in a list, you will have to review and understand the content and build a list of elements that align to the definition for ${palette.formatLabel(
  //       typeDef.name
  //     )}, which is given below. You will need to search through all content levels to find all of the relevent ${palette.formatLabel(
  //       typeDef.name
  //     )} content, and organised it into ${
  //       levelSpec.levels === 1
  //         ? "a single level of elements."
  //         : "a two level hierarchy of elements"
  //     }`,
  //   })

  //   baseMessages.push({
  //     role: "user",
  //     content: `When locating all relevant content to use when creating ${palette.formatLabel(
  //       typeDef.name
  //     )} elements, evaluate top level content and also any nested content that may contain ${palette.formatLabel(
  //       typeDef.name
  //     )} elements.`,
  //   })

  //   baseMessages.push({
  //     role: "user",
  //     content: `You must ensure that all elements that you extract from the input content strictly align to the definition of ${palette.formatLabel(
  //       typeDef.name
  //     )}, including the name given to elements and descriptions, using this definition: ${elementTypePrompt}`,
  //   })
  // }

  if (overview.trim() !== "") {
    baseMessages.push({
      role: "user",
      content: `The overall context for your response is: ${overview}`,
    })
  }

  // if (levelSpec.levels === 1) {
  //   baseMessages.push({
  //     role: "user",
  //     content: `You are creating a single-level ${typeDef.name} element structure. Your response should be a flat array of ${typeDef.name} elements, and so extract all relevant content and flatten it into a single layer of ${typeDef.name} elements. You can also consider any information in files provided as well.`,
  //   })
  // } else {
  //   baseMessages.push({
  //     role: "user",
  //     content: `You are creating a two-level ${typeDef.name} element hierarchy. Your response should be a two level hierarchy of ${typeDef.name} elements, and so extract all relevant content and organise it into a hierarchy of ${typeDef.name} elements. You can also consider any information in files provided as well.`,
  //   })
  // }

  const propsMessages = []

  if (levelSpec.props.length > 0) {
    propsMessages.push({
      role: "user",
      content: `Add the following properties to each element: ${levelSpec.props
        .map((p) => p.name)
        .join(
          ", "
        )}. These must only have a 'name' and 'value' attribute, added as a 'props' array attribute for each element. The definition of each prop type is as follows:`,
    })
    levelSpec.props.forEach((prop) => {
      propsMessages.push({
        role: "user",
        content: `'${prop.name}: ${prop.description}'`,
      })
    })
  }

  const viewRefMessages = [
    {
      role: "system",
      content:
        "Understand the context information provided with brackets like [<context name>]. Utilize this broader context to inform your response.",
    },
    ...referencedViewPrompts.map((data, index) => ({
      role: "system",
      content: `[${data.src}]\n${data.prompt.join("\n")}\n[/${data.name}]`,
    })),
  ]

  const messages = []

  console.log("%cscope", "color:lightgreen", { viewSet })
  messages.push({
    role: "system",
    content: `You are an expert in solving for this objective: '${viewSet.purpose}'.`,
  })

  if (referencedViewPrompts.length > 0) {
    messages.push(...viewRefMessages)
  }

  messages.push(
    ...createPromptMessages({
      type: pluralize(typeDef.name),
      levelSpec: levelSpec,
      context: context,
      parentElement: parentElement,
    }),
    ...baseMessages,
    ...propsMessages
  )

  console.log("%creason", "color:lightgreen", levelSpec.element_type_reason)

  const hasDescription = levelSpec.attrs.includes("description")

  if (hasDescription && levelSpec.element_type_reason) {
    // 'element_type_reason' is the AI generated description of why it chose that element type.
    // This value will only exist if the view is being created from an AI-created suggested view
    messages.push({
      type: "text",
      content: `The description for the ${typeDef.name} element should be written so that it aligns to this definition: ${levelSpec.element_type_reason}.`,
    })
  }

  if (hasDescription) {
    messages.push({
      type: "text",
      content: `The description for each element should be around ${levelSpec.max_words} words.`,
    })

    messages.push({
      type: "text",
      content:
        'The description should never be vague or generic, e.g.  "This element describes...", or "This element is used to describe...", or "This process ensures", etc but instead should be a direct and specific description that relates to the element itself.',
    })

    messages.push({
      type: "text",
      content:
        'Do not include any meta-information in the description, e.g. "This is a description of...", "This is a description for...", etc.',
    })
  }

  messages.push({
    type: "text",
    content: `The name of the element must never be the name of the ArchiMate element, i.e. never call the element ${typeDef.name}.`,
  })

  if (!hasDescription) {
    // No description
    messages.push({
      type: "text",
      content: `Do not provide any description.`,
    })
  }

  const topLevelDesc =
    levelSpec.levels === 1
      ? "actual elements of your response"
      : "top level elements of your response"
  messages.push({
    type: "text",
    content: `Do not create any empty top level element, or a top level element for a heading/title of your response. The top level array of elements provided should be the ${topLevelDesc}. Do not add any unecessary parent element.`,
  })

  // handle parsing of mainSubjectLevelsText, and revert to 1 if any errors
  let levelCount = parseInt(mainSubjectLevelsText)
  if (isNaN(levelCount)) {
    console.log("%cError parsing level count, reverting to 1", "color:red", {
      mainSubjectLevelsText,
    })
    levelCount = 1
  } else if (levelCount > 2) {
    levelCount = 2
  }

  const functions = createGptFunctionsForNextLevel({
    levelSpec,
    typeDef,
    maxWords: levelSpec.max_words,
    elementTypePrompt,
    // If we're not aligning the elements to the text response, just go with 1
    levelCount: alignElements ? levelCount : 1,
  })
  const functionName = functions[0].name

  console.log("%ccreated functions", "color:pink", { functions, messages })

  // -----------------

  console.log("%cSTART ---------------", "color:lightgreen")

  console.log("%cselected vector stores", "color:lightgreen", { vectorStores })
  const assistantVectorStoreIds =
    viewCreatorAssistant.tool_resources?.file_search?.vector_store_ids || []

  console.log("%cassistant vector stores", "color:lightgreen", {
    assistantVectorStoreIds,
  })

  // Get files for all vector stores

  const vectorStoreFileResponses = await Promise.all(
    allVectorStoreIds.map((vsId) => listVectorStoreFiles({ vsId: vsId }))
  )

  const vectorStoreFileIds = vectorStoreFileResponses.flatMap((responseItem) =>
    responseItem.data.response.data.map((dataItem) => ({
      file_id: dataItem.id,
      vector_store_id: dataItem.vector_store_id,
    }))
  )

  console.log("%cvector store file ids", "color:lightgreen", {
    vectorStoreFileIds,
  })

  // Are all vector_store_ids the same?
  const isSameVectorStore = allVectorStoreIds.every(
    (vsId) => vsId === allVectorStoreIds[0]
  )

  const threadCreated = await retrieveThread({ threadId: threadId })

  if (viewSetFiles.length > 0) {
    console.log("%cadding files to thread", "color:lightgreen", {
      viewSetFiles,
    })
    await addMessages({
      threadId,
      content: [
        {
          type: "text",
          text: `Use any content of the files provided to provide a response to the following: ${levelSpec.info}`,
        },
        {
          type: "text",
          text: `The files you can review are: ${viewSetFiles
            .map((file) => file.label)
            .join(", ")}.`,
        },
      ],
      attachments: viewSetFiles.map((file) => ({
        file_id: file.id,
        tools: [{ type: "file_search" }],
      })),
    })

    const readAttachmentsResult = await runAssistant({
      threadId,
      assistantId: viewCreatorAssistant.id,
      usage: "read attachments",
      expectedStatus: "completed",
      tools: [{ type: "file_search" }],
      toolChoice: { type: "file_search" },
      waitMillis: 1000,
    })

    console.log("%cread attachments result", "color:orange", {
      readAttachmentsResult,
    })

    const readAttachmentsMessages = await listMessages({
      threadId,
      runId: readAttachmentsResult.result.data.response.id,
    })

    console.log("%cread attachments messages", "color:orange", {
      readAttachmentsMessages,
    })
  }

  const fixMessageProps = messages.map((m) => {
    const newMsg = {
      type: "text",
      text: m.content,
    }
    return newMsg
  })

  await addMessages({
    threadId,
    content: fixMessageProps,
    attachments: viewSetFiles.map((file) => ({
      file_id: file.id,
      tools: [{ type: "file_search" }],
    })),
  })

  // const createViewFunction = viewCreatorAssistant.tools.find(
  //   (t) => t.type === "function" && t.function.name === "create_view"
  // )

  // dynamically generated depending on if we want 1 or 2 levels in function
  // i.e. does not use OpenAI platform defined function in this case.
  const functionToUse = { type: "function", function: functions[0] }

  const runResult1 = await runAssistant({
    threadId,
    assistantId: viewCreatorAssistant.id,
    usage: "create view",
    expectedStatus: "requires_action",
    //functionToUse: createViewFunction,
    functionToUse: functionToUse,
    modelName: GPT_4o_MINI_LATEST,
    tools: [{ type: "file_search" }],
    waitMillis: 1000,
  })

  // Check for error with OpenAI
  if (runResult1.result.data.response.status === "failed") {
    return {
      error: `OpenAI error - ${runResult1.result.data.response.last_error.message}`,
      json: {},
      pastedElements: [],
      text_response: "",
    }
  }

  let parsedJson
  if (runResult1.result.data.response?.required_action.submit_tool_outputs) {
    const rawJson =
      runResult1.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("%cattempting to parse JSON", "color:pink", { rawJson })

    try {
      parsedJson = JSON.parse(rawJson)["elements"]
      console.log("%cparsed JSON", "color:lightgreen", { parsedJson })
    } catch (e) {
      console.error("Error parsing JSON", e)
      return
    }
  }

  // If temp vector store created, then delete it
  if (!isSameVectorStore) {
    const vectorStoreIds =
      threadCreated.data.response.tool_resources.file_search.vector_store_ids
    console.log("%c[cleanup vector store] vector store ids", "color:orange", {
      vectorStoreIds,
    })
    for (const vsId of vectorStoreIds) {
      console.log(
        "%c[cleanup vector store] deleting vector store",
        "color:orange",
        vsId
      )
      const deleteResult = await deleteOpenAIVectorStore({ vsId })
      console.log(
        "%c[cleanup vector store] delete vector store result",
        "color:orange",
        { deleteResult }
      )
    }
  }

  if (threadId) {
    const deleteThreadResult = await deleteThread({ threadId: threadId })
    console.log("delete thread result", { deleteThreadResult })
  }

  console.log("%cEND -----------------", "color:lightgreen")

  parsedJson.forEach((element) => addType(element, typeDef.index))
  parsedJson.forEach((element) => fixProps(element))
  parsedJson.forEach((element) => {
    if (!element.children) {
      element.children = []
    }
    if (!element.description) {
      element.description = ""
    }
  })

  let pastedElementsX = handlePasteAdd({
    elementDataToAdd: parsedJson,
    parent: parentElement,
    viewElements: currentView.elements,
  })

  return {
    json: parsedJson,
    pastedElements: pastedElementsX,
    //text_response: prepText2,
  }
}

/**
 * Get the messages used to generate initial text content for the next level of elements
 *
 * @param {*} param0
 * @returns
 */
const getLevelPrepMessages = ({
  elementTypePrompt,
  levelSpec,
  purpose,
  roles,
  topLevel,
  typeDef,
  useElements,
}) => {
  const message = `** Background **
  - The user is seeking to achieve this objective: ${purpose}.

  ** Task **
  - You must generate content that answers this specific question: ${
    levelSpec.info
  }.
  - You must provide a response that is centrally aligned around a set of ArchiMate ${palette.formatLabel(
    typeDef.name
  )} elements that closely align to the definition of ${palette.formatLabel(
    typeDef.name
  )} elements. 
  
  ** ${palette.formatLabel(typeDef.name)} Definition **
  - ${elementTypePrompt}

  ** Constraints **
  - The response must be fact-based and relevant to the request.
  - Do not provide a generic response. Assume the user already knows the generic answer to their question, and so provide a response which goes beyond a generic answer and contains information specifically addressing this request, and the overall objective. 
  - Provide specific responses that have specific detail and are not a category or vague/broad response.

  ** Additional Information **
  - Provide the response without including any conversation, or dialogue. You can include extra text but make it fact based and relevant to the response.
  - You can nest content but do not add an overall heading or title element to the response.
  - Only use valid markdown. Rule: You MUST NOT USE ANY HTML TAGS in the markdown.`

  const prepMessages = [{ role: "user", content: message }]

  if (useElements) {
    const provideUpToMsg = `Provide up to ${
      levelSpec.qty
    } ${palette.formatLabel(
      typeDef.name
    )} elements. Do not provide too few, or too many as I want the level of detail to be just right without overly summarising or overly diluting meaning.`

    switch (levelSpec.qty_selection) {
      case QTY_SELECTION_FIXED:
        prepMessages.push({
          role: "user",
          content: `Provide exactly ${levelSpec.qty} elements in total.`,
        })
        break

      case QTY_SELECTION_UPTO:
        prepMessages.push({
          role: "user",
          content: provideUpToMsg,
        })
        break

      case QTY_SELECTION_ANY:
        if (!roles.includes(AIM_AI)) {
          // Even if the user selects 'any', if they don't have the AI role, we still want to enforce a limit
          prepMessages.push({
            role: "user",
            content: provideUpToMsg,
          })
        } else {
          // unnecessary...
          // prepMessages.push({
          //   role: "user",
          //   content: `Provide whatever number of ${palette.formatLabel(
          //     typeDef.name
          //   )} elements best answers the question, i.e. a middle ground that avoids overly summarising the results, or provides unnecessary detail.`,
          // })
        }

      default:
    }
  }

  // prepMessages.push({
  //   role: "user",
  //   content:
  //     "Provide the response without including any conversation, or dialogue. You can include extra text but make it fact based and relevant to the response.",
  // })

  // prepMessages.push({
  //   role: "user",
  //   content: `You can nest content but do not add an overall heading or title element to the response.`,
  // })

  // if (topLevel && levelSpec.levels === 2) {
  //   console.log("%crequesting two layer response", "color:pink")
  //   prepMessages.push({
  //     role: "user",
  //     content: `Make sure the response organises content into a two-layer hierarchy of ${typeDef.name} elements, with the top level elements being the ${typeDef.name} elements, and the second level elements being the supporting ${typeDef.name} elements.`,
  //   })
  //   prepMessages.push({
  //     role: "user",
  //     content: `In the second level of detail, provide enough child elements to properly support the parent element, but do not provide too many child elements that it becomes overly detailed.`,
  //   })
  // }

  // if (useElements) {
  //   prepMessages.push(
  //     {
  //       role: "user",
  //       content: `The content of the response must be ${typeDef.name} elements, which has the following element type definition. The content you provide must strictly align to this definition:`,
  //     },
  //     { role: "user", content: `--- ELEMENT TYPE DEFINITION ---` },
  //     { role: "user", content: elementTypePrompt },
  //     { role: "user", content: `--- END ELEMENT TYPE DEFINITION ---` }
  //   )
  // }

  //if (useElements) {
  // prepMessages.push({
  //   role: "user",
  //   content:
  //     "Make sure the response is formatted nicely and easy to read, incl. making any content for elements at nested at the top level, and information relating to the element is indented under it. e.g. if there was an element called, say, 'Billing Management' then that would be formatted as **Billing Management**: <description>, and any additional information for that element would be indented under it. Any child elements for Billing Management would also be indented under Billing Management.",
  // })

  // prepMessages.push({
  //   role: "user",
  //   content: `Only provide information aligned to its definition for this element type, incl. name, description, and any other fields relevant for that type. Always format the name and description as ** <name>: <description>, but put additional attributes for that element indented undeneath.`,
  // })

  // prepMessages.push({
  //   role: "user",
  //   content:
  //     "Do not make up any attributes to provide for the element that are not part of its definition, and also focus on providing a comprehensive set of elements with a description rather than providing lots of attributes per element.",
  // })
  //}

  // prepMessages.push({
  //   role: "user",
  //   content:
  //     "Only use valid markdown. Rule: You MUST NOT USE ANY HTML TAGS in the markdown.",
  // })

  return prepMessages
}

const getAutoCountOfNextLevel = async ({
  referencedViewPrompts,
  viewSet,
  levelSpec,
  parentElement,
  gptModel,
}) => {
  if (!levelSpec.type) {
    return 0
  }

  const typeDef = palette.getElementTypeByIndex(levelSpec.type)

  const viewRefMessages = [
    {
      role: "system",
      content: `You are an expert in solving for this objective: ${viewSet.scope}.`,
    },
    ...referencedViewPrompts.map((data, index) => ({
      role: "system",
      content: `[${data.src}]\n${data.prompt.join("\n")}\n[/${data.src}]`,
    })),
  ]
  const messages = []

  if (viewSet.overview.trim() !== "") {
    messages.push({
      role: "user",
      content: `The context for your estimation is: ${viewSet.overview}`,
    })
  }

  const msgStart = `Given the provided context, I need an accurate estimation of the ${
    !parentElement ? "top level first level of detail " : ""
  }number of ${palette.formatLabel(
    typeDef.name
  )} elements required to support the objective of '${
    viewSet.purpose
  }'. Please consider the entire scope, depth, and breadth of the subject matter.`

  if (parentElement) {
    messages.push({
      role: "user",
      content: `${msgStart} to break down ${
        parentElement.name
      } to the next logical level of detail of ${palette.formatLabel(
        typeDef.name
      )} elements.`,
    })
  } else {
    messages.push({
      role: "user",
      content: `${msgStart} This first set of elements will provide the foundational pillars for subsequent, more detailed logical levels of detail, and so consider all of the content available but then summarise this into a reasonably small set of top level items.`,
    })
  }

  if (referencedViewPrompts.length > 0) {
    messages.push(...viewRefMessages)
  }

  if (levelSpec.info.trim() !== "") {
    messages.push({
      role: "user",
      content: `The count information I require is in relation to this query: ${levelSpec.info}`,
    })
  }

  if (typeDef.label) {
    messages.push({
      role: "user",
      content: `The definition of the ${palette.formatLabel(
        typeDef.name
      )} element that you need to estimate the count is as follows: ${
        typeDef.label
      }`,
    })
  }

  messages.push({
    role: "user",
    content: `Please think through what the actual correct answer, in terms of what elements you would respnd with, and tell me what the boundary min and max number of answers are of ${palette.formatLabel(
      typeDef.name
    )} elements${
      parentElement
        ? ` to break down '${parentElement.name}' (defined as '${parentElement.label}') to the next level of detail`
        : ""
    }.`,
  })

  const functions = [
    {
      name: "get_auto_count_of_next_level",
      description: "Get the auto count of the next level",
      parameters: {
        type: "object",
        properties: {
          min: {
            type: "number",
            description:
              "The minimum number of elements to generate at the requested logical level of detail",
          },
          max: {
            type: "number",
            description:
              "The maximum number of elements to generate at the requested logical level of detail",
          },
        },
        required: ["min", "max"],
      },
    },
  ]

  let result
  try {
    result = await createChatCompletionWithFunctions({
      messages: messages,
      model: gptModel,
      funcs: functions,
      function_call: { name: "get_auto_count_of_next_level" },
    })
    console.log("%ccount result", "color:lightgreen", result)
  } catch (e) {
    console.log("error", e)
    return { error: e }
  }
  const response = result.data.response

  if (result.data.error) {
    return { error: result.data.error }
  }

  const funcCallResultStr = response.choices[0].message.function_call.arguments

  const json = JSON.parse(funcCallResultStr)

  // const responseStr = response.choices[0].message.content

  // const json = getJSONObj(responseStr)

  // if (isNaN(json.min) || isNaN(json.max)) {
  //     return { error: "Invalid range", json: json }
  // }

  const countResult = { count: json.max, range: json }

  console.log("count result", countResult)

  return countResult
}

const getAvoidSiblingOverlapPrompt = ({ parentElement, siblings }) => {
  return `As you create the next level of detail for '${
    parentElement.name
  }', be aware that its sibling elements are: ${siblings
    .map((s) => s.name)
    .join(
      ", "
    )} and so do not create elements that are too similar to these or have the same name.`
}

const getPromptsForReferencedViews = async ({
  accountId,
  views,
  modelCache,
  prompt,
  loadedViewsCache = [],
}) => {
  const viewReferences = getViewReferences({ prompt: prompt })
  const parsed = parseViewReferences({ viewReferences: viewReferences })

  // console.log("viewReferences", {
  //   parsed,
  //   viewReferences,
  //   prompt: prompt,
  //   views,
  // })

  const { loadedViews, missingViews } = await getReferencedPromptViews({
    accountId,
    prompt,
    views,
    modelCache,
    loadedViewsCache,
  })

  //console.log("%cviewPromptData", "color:yellow", { loadedViews, missingViews })

  const viewPrompts = loadedViews.map((data) => {
    //console.log("create view prompt", { data })

    if (data.type === "design") {
      const viewPrompt = createViewPrompt({ view: data.view })
      return { src: data.src, prompt: viewPrompt }
    } else if (data.type === "project" || data.type === "component") {
      //console.log("create project/component prompt", { data })

      const modelCacheKey = createModelCacheKey(data.file, data.id, data.type)
      const modelCacheItem = searchModelCache({
        modelCacheKey: modelCacheKey,
        modelCache: modelCache,
      })
      //console.log("modelCacheItem", { modelCacheItem, modelCacheKey })
      const view = modelCacheItem.model.views.find((v) => v.name === data.name)
      //console.log("view", view)
      const promptData = createPromptDataFromModelCache(modelCacheItem, view)
      //console.log("promptData", promptData)

      const prompt = createChatPrompt({
        promptData,
        promptLayers: palette.LAYERS.map((layer) => layer.name),
        includeProperties: true,
        includeDoco: true,
        includeIds: false,
      })

      return { src: data.src, prompt: prompt }
    }
  })

  //console.log("%cviewPrompts", "color:lightgreen", { viewPrompts })

  return { viewPrompts, loadedViews, missingViews }
}

const loadDesignViewRefs = async ({ viewRefs, accountId }) => {
  //console.log("load design view refs", { viewRefs })

  const parsed = parseViewReferences({
    viewReferences: viewRefs.map((viewRef) => viewRef.src),
  })

  //console.log("parsed", parsed)

  const loadPromises = parsed.map(async (viewRef) => {
    //console.log("find view set", { accountId, name: viewRef.parent_name })
    const viewSetRefs = await db
      .collection("view_sets")
      .where("account_id", "==", accountId)
      .where("name", "==", viewRef.parent_name)
      .get()
    const viewSetRef = viewSetRefs.docs[0]

    //console.log("found view set ref", { ref: viewSetRef?.data() })

    const views = await db
      .collection("views")
      .where("account_id", "==", accountId)
      .where("view_set_id", "==", viewSetRef.id)
      .get()

    const viewDoc = views.docs[0]
    const view = {
      src: viewRef.src,
      view: { id: viewDoc.id, ...viewDoc.data() },
    }
    //console.log("view", view)
    return view
  })

  const loadedViews = await Promise.all(loadPromises)
  //console.log("loadedViews", { loadedViews })
  return loadedViews
}

const createViewPrompt = ({ view }) => {
  const promptData = createChatPromptData({
    currentView: view,
    selectedItemId: undefined,
    hiddenProps: [],
  })

  console.log("promptData", promptData)

  const prompts = createChatPrompt({
    promptData,
    // All layers
    promptLayers: palette.LAYERS.map((layer) => layer.name),
    includeProperties: true,
    includeDoco: false,
    includeIds: false,
  })

  return prompts
}

const getLeafNodes = (jsonArr) => {
  console.log("%cfind leaf nodes", "color:yellow", { jsonArr })

  const result = []

  const getLeafNodesRec = (json) => {
    if (json.children && json.children.length > 0) {
      json.children.forEach((child) => getLeafNodesRec(child))
    } else {
      result.push(json)
    }
  }

  jsonArr.forEach((json) => getLeafNodesRec(json))

  return result.flat()
}

/**
 * Take the prep text that has been generated, either using a chat completions approach (without file inputs),
 * or a thread approach (with file inputs), and post process it to extract the element information, so that we can
 * use this to create the model view and have a clean set of elements to work with.
 *
 * @param {*} param0
 * @returns
 */
const postProcessElementPrepText = async ({ prepText, levelSpec }) => {
  let elementPrepResult

  if (prepText) {
    // Extract element info from prepText since the assistant doesn't seem smart enough to do so, and so
    // will create elements call 'introduction', and 'conclusion' which are not needed.

    console.log("%cpost-process prepText", "color:lightgreen", { prepText })

    elementPrepResult = await createChatCompletion({
      messages: [
        {
          role: "user",
          content: `Extract only the ${palette.formatLabel(
            palette.getElementNameByIndex(levelSpec.type)
          )} elements from INPUT TEXT text. Do not include any introductory or conclusion text.`,
        },
        {
          role: "user",
          content: `Provide the name, description, and level for each in a nested JSON array where each array object has a level, name, and description, with markdown formatting removed, ensuring that each ${levelSpec.type} element has a corresponding set of child ${levelSpec.type} elements that support it that matches the input content provided.`,
        },
        {
          role: "user",
          content: `For the description, summarise the information that has been provided into a concise description for each ${levelSpec.type} element.`,
        },
        {
          role: "user",
          content: `For the name, remove any numbering or bullet points, and ensure that the name is a concise and clear name for the ${levelSpec.type} element.`,
        },
        {
          role: "user",
          content:
            "Be careful not to include any introductory, heading, or conclusion text in your response, and only include the content that is relevant to the elements you are extracting.",
        },
        {
          role: "user",
          content: `Only make the JSON array nested if the input data is nested, otherwise provide a flat array of elements.`,
        },
        {
          role: "user",
          content:
            "When numbering, top level elements should be numbered 1, 2, 3, etc. and child elements should be numbered 1.1, 1.2, 1.3, etc. under the correct parent element.",
        },
        {
          role: "user",
          content: `--- INPUT TEXT ---\n${prepText}\n--- END INPUT TEXT ---`,
        },
      ],
      model: GPT_o1_MINI_LATEST,
    })

    console.log("%celement prep result", "color:orange", {
      elementPrepResult,
    })
  }

  const elementPrepText =
    elementPrepResult?.data.response.choices[0].message.content

  console.log("%celement prep text", "color:orange", {
    elementPrepText,
  })

  return elementPrepText
}

/**
 *
 * @param {*} count
 * @param {*} type
 * @param {*} levelSpec
 * @param {*} context
 * @param {*} item | The item for which the breakdown is being created
 * @returns
 */
const createPromptMessages = ({ type, levelSpec, context, parentElement }) => {
  const messages = []

  if (parentElement) {
    messages.push(...getNextLevelDownMessages({ levelSpec, parentElement }))
  } else {
    if (
      levelSpec.levels > 1 &&
      (levelSpec.qty_selection === QTY_SELECTION_FIXED ||
        levelSpec.qty_selection === QTY_SELECTION_UPTO)
    ) {
      messages.push({
        role: "user",
        content: `There should only be up to ${
          levelSpec.qty
        } ${palette.formatLabel(
          type
        )} elements across all array levels of the response.`,
      })
    }
  }

  //messages.push(
  // {
  //   role: "user",
  //   content: `The length of any description provided should be no more than ${levelSpec.max_words} words.`,
  // }
  // {
  //   role: "user",
  //   content:
  //     "Never create an element which is give a name of an ArchiMate element.",
  // }
  //)

  if (context) {
    messages.push({
      role: "user",
      content:
        "The parent element under which you are creating child elements is as follows and this would provide context for your response: " +
        context.name,
    })
  }

  return messages
}

// Set an 'id' value for each prop, and set type='text'
const fixProps = (element) => {
  const fixProp = (prop, propId) => {
    // See if GPT has returned props in the wrong format, e.g
    // {Type: 'Equipment', id: 1, type: 'text'}
    // instead of
    // { name: 'Type': value: 'Equipment', id: 1, type: 'text'}

    // See if expected prop attributes are there
    const { name, value, ...other } = prop
    if (!name && !value) {
      // Assume that 'other' is the name and value, and set it as such
      prop.name = Object.keys(other)[0]
      prop.value = other[prop.name]

      // Delete the 'other' attribute
      delete prop[other]

      console.log("repaired prop", prop)
    }

    prop.id = propId
    prop.type = "text"
  }

  if (element.props) {
    element.props.forEach((prop, index) => fixProp(prop, index + 1))
  }

  if (element.children) {
    element.children.forEach((child) => fixProps(child))
  }

  return element
}

const pluralize = (word) => {
  if (word.endsWith("y")) {
    return word.substring(0, word.length - 1) + "ies"
  } else {
    return word + "s"
  }
}

const addType = (element, type) => {
  element.type = type
  if (element.children) {
    element.children.forEach((child) => addType(child, type))
  }
}

const createGptFunctionsForNextLevel = ({
  levelSpec,
  typeDef,
  maxWords,
  elementTypePrompt,
  // 1 or 2 levels
  levelCount,
}) => {
  // const nameDescription = `The name of the ${palette.formatLabel(
  //   typeDef.name
  // )}. ${typeDef.label ? `The rules for the name are: ${typeDef.label}` : ""}`

  const nameDescription = `Name of the element. This must never be the name of an ArchiMate element.`

  const parameters = {
    type: "object",
    properties: {
      elements: {
        type: "array",
        items: {
          type: "object",
          properties: {
            name: {
              type: "string",
              description: nameDescription,
            },
          },
          required: ["name"],
          additionalProperties: false,
        },
      },
    },
    required: ["elements"],
    additionalProperties: false,
  }

  // Cant use minItems in strict mode
  // switch (levelSpec.qty_selection) {
  //   case QTY_SELECTION_ANY:
  //     delete parameters.properties.elements.minItems
  //     delete parameters.properties.elements.maxItems
  //     break

  //   case QTY_SELECTION_UPTO:
  //     delete parameters.properties.elements.minItems
  //     parameters.properties.elements.maxItems = levelSpec.qty_to_use
  //     break

  //   case QTY_SELECTION_FIXED:
  //     parameters.properties.elements.minItems = levelSpec.qty_to_use
  //     parameters.properties.elements.maxItems = levelSpec.qty_to_use
  //     break

  //   default:
  // }

  if (levelSpec.props.length > 0) {
    parameters.properties.elements.items.properties.props = {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            description: "The name of the property",
          },
          value: {
            type: "string",
            description: "The value of the property",
          },
          reason: {
            type: "string",
            description: "The reason why the value was selected",
          },
        },
        required: ["name", "value", "reason"],
      },
    }
    parameters.properties.elements.items.required.push("props")
    parameters.additionalProperties = false
  }

  // Add children as a sibling property to name, which is an array of objects each with a name, description, and type.
  if (levelCount === 2) {
    parameters.properties.elements.items.properties.children = {
      type: "array",
      //minItems: 1,
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            description: `The name of the child ${palette.formatLabel(
              typeDef.name
            )}`,
          },
          description: {
            type: "string",
            description: `The description of the child ${palette.formatLabel(
              typeDef.name
            )}`,
          },
        },
        required: ["name", "description"],
        additionalProperties: false,
      },
    }
    parameters.properties.elements.items.required.push("children")
    parameters.properties.elements.items.additionalProperties = false
  }

  if (levelSpec.attrs.includes("description")) {
    const descPrompt =
      typeDef.element_description_prompt ||
      `${elementTypePrompt}. DO NOT include the element type name in the description, or explain what the element type is, e.g. do not write the description in the form of 'This element means', 'This element represents', etc.`

    parameters.properties.elements.items.properties.description = {
      type: "string",
      description: descPrompt,
      //minLength: Math.max(0, parseInt(maxWords) - 5) * AVERAGE_CHARS_PER_WORD,
      //maxLength: parseInt(maxWords) * AVERAGE_CHARS_PER_WORD,
    }
    parameters.properties.elements.items.required.push("description")
  }

  const functionName = `get_elements_with_${levelSpec.attrs.join("_")}`

  console.log("%cfunction name", "color:lightgreen", functionName)

  const functions = [
    {
      name: functionName,
      description: "Get elements",
      parameters: parameters,
      strict: true,
    },
  ]

  console.log("%cfunctions", "color:orange", { functions })

  return functions
}

const getViewReferences = ({ prompt }) => {
  const viewRefRegex = /\[(.*?)\]/g
  const viewReferences = []
  let match
  while ((match = viewRefRegex.exec(prompt)) != null) {
    viewReferences.push(match[1])
  }

  // Strip any surrounding [ or ] characters from the results

  viewReferences.forEach((viewRef, index) => {
    if (viewRef.startsWith("[")) {
      viewReferences[index] = viewRef.substring(1)
    }
    if (viewRef.endsWith("]")) {
      viewReferences[index] = viewRef.substring(0, viewRef.length - 1)
    }
  })

  return viewReferences
}

const parseViewReferences = ({ viewReferences }) => {
  const parsed = viewReferences
    .map((viewRef) => {
      const parts = viewRef.split(":")
      if (parts.length > 0) {
        const type = parts[0].toLowerCase()
        switch (type) {
          case "design":
            if (parts.length === 3) {
              return {
                type: type,
                parent_name: parts[1],
                name: parts[2],
                src: viewRef,
              }
            }
            console.error(`Expecting 3 parts, found ${parts.length}`, { parts })
            return undefined

          case "project":
            if (parts.length === 4) {
              return {
                type: type,
                parent_name: parts[1],
                file: parts[2],
                name: parts[3],
                src: viewRef,
              }
            }
            console.error(`Expecting 4 parts, found ${parts.length}`, { parts })
            return undefined

          case "component":
            if (parts.length === 4) {
              return {
                type: type,
                parent_name: parts[1],
                file: parts[2],
                name: parts[3],
                src: viewRef,
              }
            }
            console.error(`Expecting 4 parts, found ${parts.length}`, { parts })
            return undefined

          default:
            console.log("Unknown view ref type", viewRef)
            return undefined
        }
      }
    })
    .filter((ref) => ref !== undefined)

  return parsed
}

const getLevelPrepTextWithThread = async ({
  elementTypePrompt,
  levelSpec,
  purpose,
  roles,
  topLevel,
  typeDef,
  threadId,
  inputFiles,
  viewCreatorAssistant,
  useElements,
}) => {
  let createLevelPrepMessages

  const prepMessages = getLevelPrepMessages({
    elementTypePrompt,
    levelSpec,
    purpose,
    roles,
    topLevel,
    typeDef,
    useElements,
  })

  if (topLevel) {
    await addMessages({
      threadId,
      content: prepMessages.map((msg) => ({
        type: "text",
        text: msg.content,
      })),
    })

    if (inputFiles.length > 0) {
      console.log("%cadd input files", "color:lightgreen", { inputFiles })

      const fileUsageMessages = [
        {
          type: "text",
          text: `Use any content from the files that aligns to ${typeDef.name} type information provided to answer this question: ${levelSpec.info}`,
        },
        {
          type: "text",
          text: `The files you can review are: ${inputFiles
            .map((file) => file.label)
            .join(", ")}.`,
        },
      ]

      if (useElements) {
        // We only need go guide the AI to rename content if we're going to be using it to create ArchiMate elements
        fileUsageMessages.push({
          type: "text",
          text: `If necessary, you can reword element names to align to naming conventions for element types, e.g. processes are nouns. Refer to the element definitions on how to name element types.`,
        })
      }

      await addMessages({
        threadId,
        content: fileUsageMessages,
        attachments: inputFiles.map((file) => ({
          file_id: file.id,
          tools: [{ type: "file_search" }],
        })),
      })
    }

    const createPrepTextResult = await runAssistant({
      threadId,
      assistantId: viewCreatorAssistant.id,
      usage: "create prep text",
      expectedStatus: "completed",
      tools: [{ type: "file_search" }],
      toolChoice: { type: "file_search" },
      waitMillis: 1000,
    })

    console.log("%ccreate prep text result", "color:orange", {
      createPrepTextResult,
    })

    createLevelPrepMessages = await listMessages({
      threadId,
      runId: createPrepTextResult.result.data.response.id,
    })

    console.log("%clist level prep messages", "color:orange", {
      createLevelPrepMessages,
    })

    // Be able to strip the number after the : and before the † out of a string like this: 【6:2†source】
    const getCitationIndex = (str) => {
      console.log("%cstr", "color:lightgreen", { str })
      const parts = str.split(":")
      if (parts.length > 1) {
        const num = parts[1].split("†")[0]
        return parseInt(num)
      }
      return -1
    }

    const fileCitationsRef =
      createLevelPrepMessages.data.response.data[0].content[0].text.annotations

    console.log("%cfile citations ref", "color:lightgreen", {
      fileCitationsRef,
    })

    const fileCitations = fileCitationsRef.map((annotation) => ({
      file_id: annotation.file_citation.file_id,
      text: annotation.text,
      citation_index: getCitationIndex(annotation.text),
    }))

    console.log("%cfile citations", "color:lightgreen", { fileCitations })

    const runId = createPrepTextResult.result.data.response.id

    console.log("%cgetting run steps", "color:pink", { threadId, runId })

    const runSteps = await listRunSteps({ threadId, runId })

    console.log("%crun steps", "color:pink", { runSteps })

    const lastStep =
      runSteps.data.response.data[runSteps.data.response.data.length - 1]

    const fileSearchInfo = lastStep.step_details.tool_calls[0]?.file_search

    console.log("%cfile search info", "color:lightgreen", { fileSearchInfo })

    if (fileSearchInfo) {
      const usedCitations = fileSearchInfo.results.filter((item, index) => {
        const isCitationUsed = fileCitations.find(
          (citation) =>
            citation.citation_index === index &&
            citation.file_id === item.file_id
        )
        return isCitationUsed
      })

      //      const citationText = fileSearchInfo.results.map((result) => {
      const citationText = usedCitations.map((result) => {
        const citationLine = result.content[0].text

        console.log("%ccitation line", "color:lightgreen", { citationLine })

        // Get first 10 words of citation text

        const firstTen = citationLine.split(" ").slice(0, 10).join(" ")

        return firstTen
      })

      const uniqueCitations = [...new Set(citationText)]

      console.log("%ccitation text", "color:lightgreen", { uniqueCitations })
    }
  }

  console.log("%ccreate level prep messages", "color:orange", {
    createLevelPrepMessages,
  })

  const prepText =
    createLevelPrepMessages.data.response.body.data[0].content[0].text.value

  return prepText
}

/**
 * Get text to use as the basis of the view level being created, using a chat completion approach so we can use the newer Orion model.
 * This approach cannot use OpenAI file inputs.
 *
 * @param {*} param0
 * @returns
 */
const getLevelPrepTextWithChatCompletion = async ({
  elementTypePrompt,
  levelSpec,
  purpose,
  roles,
  topLevel,
  typeDef,
}) => {
  let createLevelPrepResult

  const prepMessages = getLevelPrepMessages({
    elementTypePrompt,
    levelSpec,
    purpose,
    roles,
    topLevel,
    typeDef,
  })

  console.log("%cprep messages", "color:lightgreen", { prepMessages })

  if (topLevel) {
    createLevelPrepResult = await createChatCompletion({
      messages: prepMessages,
      model: GPT_o1_MINI_LATEST,
    })

    console.log("%ccreate level prep result", "color:orange", {
      createLevelPrepResult,
    })
  }

  const prepText =
    createLevelPrepResult?.data.response.choices[0].message.content

  return prepText
}

/**
 * Get the views referenced in a prompt, which can reference a project, component, or design view
 *
 * @param prompt The prompt that we want to parse to check for references, i.e. [<view>]
 * @param views The views in a view set that can be referenced in a prompt
 * @param modelCache The model cache that contains the project and component models that can be referenced in a prompt
 */
const getReferencedPromptViews = async ({
  accountId,
  prompt,
  views,
  modelCache,
  loadedViewsCache,
}) => {
  const loadedViews = []
  const missingViews = []

  const viewReferences = getViewReferences({ prompt: prompt })
  const parsedViewRefs = parseViewReferences({ viewReferences: viewReferences })

  for (const [index, viewRef] of parsedViewRefs.entries()) {
    switch (viewRef.type) {
      case "design":
        const view = views.find((v) => v.name === viewRef.name)
        if (view) {
          loadedViews.push({
            src: viewRef.src,
            parent_name: viewRef.parent_name,
            type: viewRef.type,
            view: view,
          })
        } else {
          missingViews.push({
            src: viewRef.src,
            parent_name: viewRef.parent_name,
            type: viewRef.type,
            reason: "view not found",
          })
        }
        break

      case "project":
        // Check if in loadedViewsCache
        const projectLoaded = loadedViewsCache.find(
          (cacheItem) => cacheItem.src === viewRef.src
        )
        if (projectLoaded) {
          //console.log("project ref in cache", { projectLoaded })
          loadedViews.push(projectLoaded)
          break
        }

        //console.log("load project", { viewRef, modelCache })

        const projects = await db
          .collection("projects")
          .where("name", "==", viewRef.parent_name)
          .where("account_id", "==", accountId)
          .get()

        if (projects.docs.length === 0) {
          missingViews.push({
            src: viewRef.src,
            file: viewRef.file,
            parent_name: viewRef.parent_name,
            reason: "project not found in db",
          })
        } else {
          // Get doc id of projects
          const projectRef = projects.docs[0].ref
          const projectKey = createModelCacheKey(
            viewRef.file,
            projectRef.id,
            "project"
          )

          const projectModelCacheItem = searchModelCache({
            modelCacheKey: projectKey,
            modelCache: modelCache,
          })

          const baseProjectResult = {
            type: viewRef.type,
            src: viewRef.src,
            id: projectRef.id,
            file: viewRef.file,
            parent_name: viewRef.parent_name,
          }

          if (projectModelCacheItem) {
            // See if the view is present in the model cache item
            const view = projectModelCacheItem.model.views.find(
              (v) => v.name === viewRef.name
            )

            if (view) {
              loadedViews.push({
                ...baseProjectResult,
                name: viewRef.name,
                view: projectModelCacheItem,
              })
            } else {
              missingViews.push({
                ...baseProjectResult,
                reason: "project found, but view not found in project",
              })
            }
          } else {
            missingViews.push({
              ...baseProjectResult,
              reason: "project not found in cache",
            })
          }
        }

        break

      case "component":
        //console.log("load component", { viewRef })

        // Check if in loadedViewsCache
        const componentLoaded = loadedViewsCache.find(
          (cacheItem) => cacheItem.src === viewRef.src
        )
        if (componentLoaded) {
          console.log("component ref in cache", { componentLoaded })
          loadedViews.push(componentLoaded)
          break
        }

        const components = await db
          .collection("components")
          .where("name", "==", viewRef.parent_name)
          .where("account_id", "==", accountId)
          .get()

        // Get doc id of projects

        if (components.docs.length === 0) {
          missingViews.push({
            type: viewRef.type,
            src: viewRef.src,
            file: viewRef.file,
            parent_name: viewRef.parent_name,
            reason: "component not found in db",
          })
        } else {
          const componentRef = components.docs[0].ref
          const componentKey = createModelCacheKey(
            viewRef.file,
            componentRef.id,
            "component"
          )
          //console.log("%ccomponent key", "color:orange", { viewRef, componentKey })
          const componentModelCacheItem = searchModelCache({
            modelCacheKey: componentKey,
            modelCache: modelCache,
          })

          const baseComponentResult = {
            type: viewRef.type,
            src: viewRef.src,
            id: componentRef.id,
            file: viewRef.file,
            parent_name: viewRef.parent_name,
          }

          if (componentModelCacheItem) {
            //console.log("modelCacheItem", componentModelCacheItem)
            // See if the view is present in the model cache item
            const view = componentModelCacheItem.model.views.find(
              (v) => v.name === viewRef.name
            )

            if (view) {
              loadedViews.push({
                ...baseComponentResult,
                name: viewRef.name,
                view: view,
              })
            } else {
              missingViews.push({
                ...baseComponentResult,
                reason: "project found, but view not found in project",
              })
            }
          } else {
            missingViews.push({
              ...baseComponentResult,
              reason: "project not found in cache",
            })
          }
        }
        break

      default:
        console.log("Unknown ref type", viewRef.src)
    }
  }

  return { loadedViews, missingViews }
}

const getNextLevelDownMessages = ({ levelSpec, parentElement }) => {
  const messages = []

  const nextLevelTypeDef = palette.getElementTypeByIndex(levelSpec.type)

  const parentElementTypeDef = palette.getElementTypeByIndex(parentElement.type)

  const nextLevelDownStr = `From the given parent '${parentElement.name}' ${parentElementTypeDef.name}, list child elements that go one logical level deeper, avoiding extremes of detail or breadth. Each child should distinctly expand on the parent topic.`

  messages.push({
    role: "user",
    content: nextLevelDownStr,
    // content: `I want you to provide the next level of detail for '${
    //     parentElement.name
    // }', which is a '${palette.formatLabel(parentElementTypeDef.name)}'`,
  })

  messages.push({
    role: "user",
    content: `The child elements to be generated are of type '${palette.formatLabel(
      nextLevelTypeDef.name
    )}'`,
  })

  if (levelSpec.auto_qty) {
    messages.push({
      role: "user",
      content: `Think about how much content is available to use, and provide up to ${levelSpec.qty} elements.`,
    })
  } else {
    messages.push({
      role: "user",
      content: `Try to provide exactly ${levelSpec.qty} elements.`,
    })
  }

  if (parentElement.description) {
    messages.push({
      role: "user",
      content: `The description for '${parentElement.name}' is: '${parentElement.description}'`,
    })
  }

  return messages
}

const getQuestionSubject = async ({ question }) => {
  const getMainSubjectResult = await createChatCompletion({
    messages: [
      {
        role: "user",
        content: `From the following question, identify the main items or things that are being requested or inquired about. 
          Provide only the plural noun or noun phrase representing these items, using the exact words from the question.
          Include necessary qualifiers or context to precisely identify the items, but exclude any action verbs or additional explanations.
          For example, if the question is "What strategies can we implement to improve customer satisfaction?", the response should be "Customer satisfaction improvement strategies".
          Do not overly include qualifiers, use only the most directly related qualifier to the items.`,
      },
      {
        role: "user",
        content: question,
      },
    ],
    model: GPT_o1_MINI_LATEST,
  })

  const mainSubject =
    getMainSubjectResult.data.response.choices[0].message.content

  return mainSubject
}

/**
 *
 * @param {Re} param0
 * @returns A JSON array of elements with 'name' and 'description' attributes
 */
const getBatchDescription = async ({
  scope,
  typeDef,
  batch,
  aimAssistant,
  roles,
}) => {
  const structureMessage = `Please create a 'description' for ${batch.length} ${typeDef.name} the following items:`

  const itemMessages = batch.map((element, index) => ({
    role: "user",
    content: `${element.name}`,
  }))

  console.log("%cAIM assistant", "color:yellow", aimAssistant)

  const maxWords = 25

  const messages = [
    { role: "user", content: structureMessage },
    ...itemMessages,
    {
      role: "user",
      content: `Use 'name' attribute, and generate a 'description' (${maxWords} characters length).`,
    },
    {
      role: "user",
      content: `The context for this is as follows: ${scope}`,
    },
    // {
    //   role: "user",
    //   content: `Your response MUST be a JSON array with 2 attributes, 'name', and 'description' (both in lowercase). Use the element names provided for the 'name' attribute.`,
    // },
    {
      role: "user",
      content: `The ${
        typeDef.name
      } descriptions must be written in a certain style as follows and should not describe what the element type means, but rather provide a description value for the element in following this guidance for the element type: ${
        typeDef.prompt_for_description || typeDef.prompt
      }.`,
    },
  ]

  // helpful link on functions usage with GPT-4: https://medium.com/@dropthazero/harnessing-the-power-of-gpt-4-function-calls-in-nodejs-a5d18a50b3a2
  // info
  // - top level param must be object, and then can hold array
  const functions = [
    {
      name: "get_descriptions",
      description: "Get descriptions for elements",
      parameters: {
        type: "object",
        properties: {
          descriptions: {
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  description:
                    "The name of the element to generate the description for",
                },
                description: {
                  type: "string",
                  description: `The description for the provided 'name' to be generated of ${maxWords} words. Do not describe the definition of the element type, but a description of the element itself.`,
                  minLength: Math.max(0, maxWords - 5) * AVERAGE_CHARS_PER_WORD,
                  maxLength: maxWords * AVERAGE_CHARS_PER_WORD,
                },
              },
              required: ["name", "description"],
            },
          },
        },
        required: ["descriptions"],
      },
    },
  ]

  const descResult = await createChatCompletionWithFunctions({
    messages: messages,
    model: getModel({ roles, funcName: FUNCTION_GET_BATCH_DESCRIPTIONS }),
    funcs: functions,
    function_call: { name: "get_descriptions" },
  })

  //console.log("get descriptions result", descResult)

  if (descResult.data.error) {
    return { error: descResult.data.error }
  }

  const funcStr =
    descResult.data.response.choices[0].message.function_call.arguments

  //console.log("funcStr", funcStr)

  let args
  try {
    args = JSON.parse(funcStr)
  } catch (e) {
    console.log("Error parsing JSON", { e, funcStr })
    return { error: e }
  }
  // If any name values have a leading and trailing single quote then remove them

  args["descriptions"].forEach((item) => {
    if (item.name.startsWith("'") && item.name.endsWith("'")) {
      item.name = item.name.substring(1, item.name.length - 1)
    }
  })

  return args["descriptions"]
}

const createElementDescriptions = async ({
  elements,
  handleUpdateDescriptions,
  setWaitingElementIds,
  scope,
  assistants,
  roles,
}) => {
  const batches = []
  console.log("%ccreateElementDescriptions: elements", "color:lightgreen", {
    elements,
  })
  const batchSize = 5
  for (let i = 0; i < elements.length; i += batchSize) {
    const batch = elements.slice(i, i + batchSize)
    batches.push(batch)
  }

  const aimAssistant = assistants.find((a) => a.name === "AIM")
  console.log("%cfound AIM Assistant", "color:lightgreen", aimAssistant)

  // elements grouped by their type attribute
  const elementsGroupedByType = elements.reduce((acc, curr) => {
    const type = curr.type
    if (!acc[type]) {
      acc[type] = []
    }
    acc[type].push(curr)
    return acc
  }, {})

  // Now split each type group into batches of 5

  const typeGroups = Object.keys(elementsGroupedByType)
  const typeBatches = typeGroups.reduce((acc, curr) => {
    const typeElements = elementsGroupedByType[curr]
    const typeBatches = []
    for (let i = 0; i < typeElements.length; i += batchSize) {
      const batch = typeElements.slice(i, i + batchSize)
      typeBatches.push(batch)
    }
    acc[curr] = typeBatches
    return acc
  }, {})

  console.log(
    "%ccreateElementDescriptions:typeBatches",
    "color:pink",
    typeBatches
  )

  // Consolidate all the type batches into a single array of batches

  const allTypeBatches = Object.values(typeBatches).reduce((acc, curr) => {
    acc.push(...curr)
    return acc
  }, [])

  console.log(
    "%ccreateElementDescriptions:allTypeBatches",
    "color:pink",
    allTypeBatches
  )

  allTypeBatches.reduce(async (prevPromise, batch) => {
    await prevPromise

    const typeDef = palette.getElementTypeByIndex(batch[0].type)
    const elementIds = batch.map((b) => b.id)
    setWaitingElementIds((curr) => [...curr, ...elementIds])

    //FIXME: this won't work if the model has a mix of element types, need batches grouped by type
    let jsonDescs = await getBatchDescription({
      scope,
      typeDef,
      batch,
      aimAssistant,
      roles,
    })

    console.log(
      "%ccreateElementDescriptions:jsonDescs",
      "color:lightgreen",
      jsonDescs
    )

    if (jsonDescs.error) {
      return { error: jsonDescs.error }
    }

    // Sometimes the responses is an object, not an array. In this case, we need to convert it to an array
    if (!Array.isArray(jsonDescs)) {
      jsonDescs = Object.keys(jsonDescs).map((key) => ({
        name: key,
        ...jsonDescs[key],
      }))
    }

    setWaitingElementIds((curr) =>
      curr.filter((id) => !batch.map((b) => b.id).includes(id))
    )

    // Map jsonDescs to each batch elements 'description' attribute based on a name attribute match
    batch.forEach((element) => {
      const desc = jsonDescs.find(
        (jsonDesc) => jsonDesc.name.toLowerCase() === element.name.toLowerCase()
      )
      if (desc) {
        element.description = desc.description
      }
    })

    handleUpdateDescriptions({ jsonElements: batch })
    return batch
  }, Promise.resolve())
}

const retrieveFiles = async ({ citations }) => {
  if (citations && citations.length > 0) {
    const filePromises = citations.map((citation) => {
      const fileId = citation.file_citation.file_id
      console.log("%cfileId", "color:pink", fileId)
      return retrieveFile({ fileId: fileId })
        .then((file) => {
          console.log("%cfile", "color:pink", file)
          return file
        })
        .catch((error) => {
          console.error(`Error retrieving file with ID ${fileId}:`, error)
        })
    })

    // Execute all file retrievals in parallel
    const files = await Promise.all(filePromises)
    return files
  } else {
    console.log("no citation")
  }
}

export {
  createContent,
  getAutoCountOfNextLevel,
  getPromptsForReferencedViews,
  createViewPrompt,
  loadDesignViewRefs,
  getViewReferences,
  parseViewReferences,
  getReferencedPromptViews,
  createElementDescriptions,
  getQuestionSubject,
}
