import {
  Box,
  CircularProgress,
  Divider,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import ArchiMateElementSelect from "./controls/ArchiMateElementSelect"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import TagIcon from "@mui/icons-material/Tag"
import AssistantIcon from "@mui/icons-material/Assistant"
import { Fragment, useEffect } from "react"
import PropSpec from "./PropSpec"
import { findMaxSeq } from "../pages/services/modelEditServices"
import UpgradeAlert from "./UpgradeAlert"
import { useState } from "react"
import {
  MAX_DESCRIPTION_WORDS,
  MAX_ELEMENTS,
  FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL,
  getAIUsageParam,
  getModel,
  QTY_SELECTION_ANY,
  QTY_SELECTION_UPTO,
  QTY_SELECTION_FIXED,
} from "../pages/services/chatGenerationServices"
import {
  getAutoCountOfNextLevel,
  getPromptsForReferencedViews,
  createViewPrompt,
  loadDesignViewRefs,
} from "../pages/services/createContentServices"
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor"
import HelpLink from "./HelpLink"
import { useMemo } from "react"
import { aiIcon } from "../pages/services/colorServices"
import * as Roles from "../pages/services/roleServices"
import { Upgrade } from "@mui/icons-material"
import { use } from "marked"

const LevelSpec = (props) => {
  const {
    modelCache,
    levelSpec,
    setLevelSpec,
    mode = "basic",
    handleDeleteLevelSpec,
    handleEnsureModelIsLoaded,
    handleSuggestElementType,
    accountId,
    index,
    currentElement,
    viewSet,
    views,
    roles,
    levelSpecCount,
    useElements,
  } = props

  const [isGettingCount, setGettingCount] = useState(false)

  const [elementTypeSuggestionReason, setElementTypeSuggestionReason] =
    useState("")

  const [
    isGeneratingElementTypeSuggestion,
    setGeneratingElementTypeSuggestion,
  ] = useState(false)

  const autoCountProps = useMemo(() => {
    if (!levelSpec.type) {
      return {}
    }

    return aiIcon
  }, [levelSpec.type])

  // Max words for paid accounts
  const DEFAULT_MAX_WORDS = 50

  // Max elements is defined in the paid account policy, so the DEFAULT_MAX_ELEMENTS is used as a fallback, but never be used.
  const DEFAULT_MAX_ELEMENTS = 30

  const maxWords = useMemo(() => {
    return (
      getAIUsageParam({ roles, paramName: MAX_DESCRIPTION_WORDS }) ||
      DEFAULT_MAX_WORDS
    )
  }, [roles])

  const maxElements = useMemo(() => {
    return (
      getAIUsageParam({ roles, paramName: MAX_ELEMENTS }) ||
      DEFAULT_MAX_ELEMENTS
    )
  }, [roles])

  const isPaid = roles.includes(Roles.AIM_AI)

  // Limit max_words to maxWords, where maxWords is lower if not a paid account
  useEffect(() => {
    const newLevelSpec = { ...levelSpec }
    let changed = false
    if (levelSpec.max_words > maxWords) {
      newLevelSpec.max_words = maxWords
    }
    if (levelSpec.qty > maxElements) {
      newLevelSpec.qty = maxElements
      changed = true
    }
    if (changed) {
      setLevelSpec(newLevelSpec)
    }
  }, [maxWords, levelSpec])

  const promptHeading = useMemo(() => {
    if (mode === "basic" && index === 0) {
      return "Prompt"
    }
    return `Prompt - Level ${index + 1}`
  }, [mode, index])

  return (
    <Paper
      sx={{ marginTop: "10px", padding: "5px", width: "auto" }}
      elevation={2}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {promptHeading}
        </Typography>

        <Box sx={{ display: "flex", marginLeft: "auto" }}>
          <IconButton onClick={() => handleDeleteLevelSpec(levelSpec.seq)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: "10px",
        }}
      >
        <Controls.TextInput
          label="Prompt"
          value={levelSpec.info}
          multiline={true}
          onChange={(e) => {
            setLevelSpec({ ...levelSpec, info: e.target.value })
            handleEnsureModelIsLoaded &&
              handleEnsureModelIsLoaded(e.target.value)
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "15px",
            marginBottom: "10px",
          }}
        >
          <ArchiMateElementSelect
            type={levelSpec.type}
            setType={(newType) => setLevelSpec({ ...levelSpec, type: newType })}
            disabled={Boolean(!useElements)}
          />
          <Box sx={{ marginLeft: "auto", marginTop: "10px" }}>
            <HelpLink
              tooltip="View ArchiMate Element Reference"
              pageName="_archimate_elements"
              url="https://architectureinmotion.com.au/archimate-elements/"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            title={`Suggest an element type based on your prompt${
              !isPaid ? " - paid feature" : ""
            }`}
          >
            <span>
              <Button
                disabled={
                  levelSpec.info.trim() === "" ||
                  isGeneratingElementTypeSuggestion ||
                  !useElements
                }
                onClick={() => {
                  setGeneratingElementTypeSuggestion(true)
                  handleSuggestElementType(levelSpec).then((result) => {
                    //console.log("result", result)
                    setLevelSpec({ ...levelSpec, type: result.id })
                    setElementTypeSuggestionReason(result.reason)
                    setGeneratingElementTypeSuggestion(false)
                  })
                }}
                variant="outlined"
                sx={{ textTransform: "none" }}
                endIcon={
                  <AssistantIcon
                    sx={
                      levelSpec.info.trim() === "" || !useElements ? {} : aiIcon
                    }
                  />
                }
              >
                Suggest Element Type
              </Button>
            </span>
          </Tooltip>
          {isGeneratingElementTypeSuggestion && (
            <CircularProgress size={20} sx={aiIcon} />
          )}
        </Box>
        {elementTypeSuggestionReason && (
          <Box sx={{ marginTop: "10px" }}>
            <Typography variant="caption" color="text.secondary">
              {elementTypeSuggestionReason}
            </Typography>
          </Box>
        )}

        <Stack direction="row" sx={{ alignItems: "center" }} gap={1}>
          <GenerateDocumentationSwitch
            levelSpec={levelSpec}
            setLevelSpec={setLevelSpec}
            disabled={Boolean(!useElements)}
          />

          <Box sx={{ maxWidth: "90px", mb: "10px", ml: "auto" }}>
            <Controls.TextInput
              label="Max Words"
              disabled={
                !levelSpec.attrs.includes("description") ||
                Boolean(!useElements)
              }
              value={levelSpec.max_words}
              onChange={(e) => {
                // Check value is a number

                if (e.target.value === "") {
                  setLevelSpec({ ...levelSpec, max_words: "" })
                  return
                }
                let value = Math.min(parseInt(e.target.value), maxWords)
                if (isNaN(value)) {
                  value = 0
                }

                setLevelSpec({ ...levelSpec, max_words: value })
              }}
              autoComplete="off"
            />
          </Box>
        </Stack>

        {/* <HierarchySwitch
          levelSpec={levelSpec}
          setLevelSpec={setLevelSpec}
          disabled={levelSpecCount > 1}
        /> */}

        <FormControlLabel
          control={
            <Box
              sx={{
                ml: "12px",
                width: "100%", // Ensure the Box takes full width
                display: "flex",
              }}
            >
              <ToggleButtonGroup
                size="small"
                sx={{ alignItems: "flex-start" }} // Align items to the left
                value={levelSpec.qty_selection}
                disabled={!useElements}
                onChange={(e, val) => {
                  if (val !== null) {
                    const newLevelSpec = {
                      ...levelSpec,
                      qty_selection: val,
                    }
                    setLevelSpec(newLevelSpec)
                  }
                }}
                exclusive
              >
                <Tooltip title="Fixed">
                  <ToggleButton value={QTY_SELECTION_FIXED}>
                    <TagIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Up to">
                  <ToggleButton value={QTY_SELECTION_UPTO}>
                    <ExpandLessIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Any">
                  <ToggleButton value={QTY_SELECTION_ANY}>
                    <AutoAwesomeIcon />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Box>
          }
        />

        <Box sx={{ mb: "10px" }}>
          {levelSpec.qty_selection === QTY_SELECTION_ANY && (
            <Typography variant="caption">
              The AI will determine the best number of elements to generate
            </Typography>
          )}

          {levelSpec.qty_selection === QTY_SELECTION_UPTO && (
            <Typography variant="caption">
              Generate elements up to your specified amount
            </Typography>
          )}

          {levelSpec.qty_selection === QTY_SELECTION_FIXED && (
            <Typography variant="caption">
              Generate a fixed number of elements
            </Typography>
          )}
        </Box>

        {levelSpec.qty_selection === "upto" && (
          <Stack direction={"column"} gap={1}>
            <Box sx={{ width: "120px" }}>
              <Controls.TextInput
                label="Auto up to"
                value={levelSpec.qty}
                disabled={!useElements}
                onChange={(e) => {
                  // Check value is a number
                  if (isNaN(e.target.value)) {
                    return
                  }

                  setLevelSpec({ ...levelSpec, qty: e.target.value })
                }}
                autoComplete="off"
              />
            </Box>
          </Stack>
        )}
        {levelSpec.qty_selection === "fixed" && (
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Box sx={{ maxWidth: "120px" }}>
              <Controls.TextInput
                label="Fixed count"
                value={levelSpec.qty}
                disabled={!useElements}
                onChange={(e) => {
                  // Check value is a number
                  if (isNaN(e.target.value)) {
                    return
                  }

                  const value = Math.min(
                    parseInt(e.target.value),
                    DEFAULT_MAX_ELEMENTS
                  )

                  if (isNaN(value)) {
                    setLevelSpec({ ...levelSpec, qty: "" })
                  } else {
                    setLevelSpec({ ...levelSpec, qty: value })
                  }
                }}
                autoComplete="off"
              />
            </Box>

            <Box>
              <Tooltip
                title={`Suggest how many elements${
                  levelSpec.type ? "" : " - Choose element type"
                }`}
              >
                <span>
                  {!isGettingCount && (
                    <IconButton
                      onClick={async () => {
                        setGettingCount(true)

                        const { viewPrompts, missingViews } =
                          await getPromptsForReferencedViews({
                            accountId,
                            views,
                            modelCache,
                            prompt: levelSpec.info,
                          })

                        const loadedViewRefs = await loadDesignViewRefs({
                          viewRefs: missingViews,
                          accountId,
                        })

                        const designViewPrompts = loadedViewRefs.map((v) => ({
                          src: v.src,
                          prompt: createViewPrompt({
                            view: v.view,
                          }),
                        }))

                        const allViewPrompts = [
                          ...viewPrompts,
                          ...designViewPrompts,
                        ]

                        getAutoCountOfNextLevel({
                          referencedViewPrompts: allViewPrompts,
                          accountId,
                          modelCache,
                          viewSet,
                          views,
                          levelSpec,
                          parentElement: currentElement,
                          gptModel: getModel({
                            roles,
                            funcName: FUNCTION_GET_AUTO_COUNT_OF_NEXT_LEVEL,
                          }),
                        }).then((result) => {
                          setLevelSpec({
                            ...levelSpec,
                            qty: result.count,
                          })
                          setGettingCount(false)
                        })
                      }}
                      disabled={!levelSpec.type || !useElements}
                    >
                      <YoutubeSearchedForIcon sx={autoCountProps} />
                    </IconButton>
                  )}
                  {isGettingCount && <CircularProgress size={20} sx={aiIcon} />}
                </span>
              </Tooltip>
            </Box>
          </Stack>
        )}

        {!isPaid && (
          <UpgradeAlert
            message={`Trial accounts may create up to ${maxElements} elements, and ${maxWords} word descriptions.`}
          />
        )}

        {mode === "extended" && (
          <>
            <Divider />

            <Box sx={{ marginTop: "10px" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Properties
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Properties added to each element
              </Typography>
            </Box>
            <RightJustifyBox>
              <Controls.Button
                onClick={() => {
                  const newLevelSpec = {
                    ...levelSpec,
                    props: [
                      ...levelSpec.props,
                      {
                        name: "",
                        description: "",
                        auto: false,
                        seq: findMaxSeq(levelSpec.props) + 1,
                      },
                    ],
                  }
                  setLevelSpec(newLevelSpec)
                }}
                label="Add Property"
              />
            </RightJustifyBox>
            {levelSpec.props.length > 0 && <Divider />}
            {levelSpec.props.map((prop, index) => (
              <Fragment key={prop.seq}>
                {index > 0 && <Divider />}
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Property {index + 1}
                </Typography>
                <PropSpec
                  accountId={accountId}
                  prop={prop}
                  setProp={(newProp) => {
                    const newProps = [...levelSpec.props]
                    newProps[index] = newProp
                    setLevelSpec({ ...levelSpec, props: newProps })
                  }}
                  handleDeleteProp={(seq) => {
                    const newLevelSpec = {
                      ...levelSpec,
                      props: levelSpec.props.filter((prop) => prop.seq !== seq),
                    }
                    setLevelSpec(newLevelSpec)
                  }}
                />
              </Fragment>
            ))}
          </>
        )}
      </Box>
    </Paper>
  )
}

const GenerateDocumentationSwitch = ({
  levelSpec,
  setLevelSpec,
  disabled = false,
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          disabled={disabled}
          checked={levelSpec.attrs.includes("description")}
          onChange={(e) => {
            // Toggle 'description' entry being part of attrs array
            if (e.target.checked) {
              setLevelSpec({
                ...levelSpec,
                attrs: ["name", "description"],
              })
            } else {
              setLevelSpec({
                ...levelSpec,
                attrs: ["name"],
              })
            }
          }}
        />
      }
      label={<Typography variant="caption">Element Descriptions</Typography>}
      labelPlacement="end"
    />
  )
}

const HierarchySwitch = ({ levelSpec, setLevelSpec, disabled }) => {
  const tooltip = disabled
    ? "Cannot request detailed responses if using more than 1 prompt level. Try removing a prompt level."
    : "Get more detailed results"

  return (
    <Tooltip title={tooltip}>
      <FormControlLabel
        control={
          <Switch
            checked={Boolean(levelSpec.levels === 2)}
            disabled={disabled}
            onChange={(e) => {
              setLevelSpec({
                ...levelSpec,
                levels: e.target.checked ? 2 : 1,
              })
            }}
          />
        }
        label={
          <Typography variant="caption">More detailed (2 levels)</Typography>
        }
        labelPlacement="end"
      />
    </Tooltip>
  )
}

const RightJustifyBox = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "auto",
        justifyContent: "flex-end",
      }}
    >
      {props.children}
    </Box>
  )
}

export default LevelSpec
